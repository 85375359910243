import React from 'react';
import { useSelector } from 'react-redux';
import JoinPage from '../JoinPage';
import IntentsPage from '../IntentsPage';
import { AppState } from '../../../types/store';

export interface Props {}

const JoinOrIntentsPage: React.FC<Props> = props => {
  const success = useSelector((state: AppState) => state.join.success);
  return success ? <IntentsPage /> : <JoinPage />;
};
JoinOrIntentsPage.displayName = 'JoinOrIntentsPage';

export default JoinOrIntentsPage;
