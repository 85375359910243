import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import { screenLoaded, clickContinue } from '../../../actions/bi/intent.bi';
import { Props } from './OnboardingPage';

type StateProps = Pick<Props, 'authMode' | 'baseDaUrl' | 'isOauth'>;
type DispatchProps = Pick<Props, 'screenLoaded' | 'clickContinue'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  authMode: state.authMode,
  baseDaUrl: state.baseDaUrl ?? '',
  isOauth: state.params.isOauth,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  screenLoaded,
  clickContinue,
};

export default connect(mapStateToProps, mapDispatchToProps);
