import ResetPasswordState from '../types/resetPassword';

const defaultState = {
  username: undefined,
  passkey: undefined,
  passwordErrorCode: undefined,
  hydrated: false,
} as ResetPasswordState;

export default function forgot(
  state = defaultState,
  action
): ResetPasswordState {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true };
  }

  return state;
}
