import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getErrorCode } from '@wix/da-shared-react/pkg/SocialLogin/redux/selectors';
import { Props } from './PasswordPage';
import { AppState } from '../../../types/store';
import {
  screenLoaded,
  termsClicked,
  policyClicked,
  joinClicked,
  redirectToLogin,
} from '../../../actions/login';
import { getKnownUrlParams } from '../../../selectors/page';

type StateProps = Pick<
  Props,
  | 'urlParams'
  | 'isChallenge'
  | 'generalError'
  | 'socialLoginError'
  | 'daProtect'
>;
type DispatchProps = Pick<
  Props,
  'screenLoaded' | 'termsClicked' | 'policyClicked' | 'joinClicked'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  urlParams: getKnownUrlParams(state),
  isChallenge: state.login.isChallenge,
  generalError: state.login.generalError,
  socialLoginError: getErrorCode(state),
  daProtect: state.params.daProtect,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  screenLoaded: () => dispatch(screenLoaded()),
  termsClicked: () => dispatch(termsClicked()),
  policyClicked: () => dispatch(policyClicked()),
  joinClicked: () => {
    dispatch(joinClicked());
    dispatch(redirectToLogin());
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
