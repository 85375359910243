import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './JoinForm';
import { AppState } from '../../../types/store';
import {
  changePassword,
  changeEmail,
  errorDisplayed,
  joinWithEmail,
  validateEmail,
} from '../../../actions/join';

type StateProps = Pick<Props, keyof AppState['join']>;
type DispatchProps = Pick<
  Props,
  | 'changePassword'
  | 'changeEmail'
  | 'errorDisplayed'
  | 'joinWithEmail'
  | 'validateEmail'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => state.join;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  changePassword,
  changeEmail,
  errorDisplayed,
  joinWithEmail,
  validateEmail,
};

export default connect(mapStateToProps, mapDispatchToProps);
