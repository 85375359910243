import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface Props {
  title?: string;
}

const SisuPageHead: React.FC<Props> = ({ title }) => {
  if (!title) {
    return null;
  }
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
SisuPageHead.displayName = 'SisuPageHead';

export default SisuPageHead;
