import { AppState } from '../types/store';
import { Location } from 'history';

export function getForgotUsername(state: AppState, location: Location) {
  const params = new URLSearchParams(location.search);
  return params.get('username') ?? state.forgot.username;
}

export function getForgotKind(state: AppState, location: Location) {
  const params = new URLSearchParams(location.search);

  // Form kind (password or username) is determined by query param
  // If a prefilled username param was found, we assume kind is "password"
  const kind = params.has('username')
    ? 'password'
    : params.get('kind') ?? 'password';

  return ['password', 'username'].includes(kind)
    ? (kind as 'password' | 'username')
    : 'password';
}
