import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import Button from '@wix/da-shared-react/pkg/Button';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
  SisuPageFooter,
} from '../SisuPage';
import SisuForm from '../../SignupSignin/SisuForm';
import PasswordInput from '../../SignupSignin/PasswordInput';

import s from './ResetPasswordPage.scss';

export interface Props {
  username?: string;
  passkey?: string;
  errorCode?: number;
}

const errorTranslationMap = {
  1: 'resetpassword.error.invalid_key',
  2: 'resetpassword.error.password_mismatch',
  3: 'resetpassword.error.setting_failed',
  4: 'resetpassword.error.too_short',
  5: 'resetpassword.error.blocked',
  6: 'resetpassword.error.reused',
  7: 'resetpassword.error.similar_to_username',
  8: 'resetpassword.error.similar_to_name',
  9: 'resetpassword.error.similar_to_email',
  10: 'resetpassword.error.unknown_weakness',
};

const ResetPasswordPage: React.FC<Props> = ({
  username,
  passkey,
  errorCode,
}) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState<string | undefined>(
    errorCode ? t(errorTranslationMap[errorCode]) : undefined
  );

  const onFormSubmit = e => {
    if (!password || !passwordConfirmation) {
      setPasswordError(t('resetpassword.error.blank'));
      e.preventDefault();
    } else if (password !== passwordConfirmation) {
      setPasswordError(t('resetpassword.error.password_mismatch'));
      e.preventDefault();
    }
  };

  return (
    <SisuPage
      className={s['root']}
      bodyClassName={s['body']}
      title={t('resetpassword.page.title')}
      origin="/users/login"
      errorTextKey="login"
    >
      <SisuPageHeader className={s['header']}>
        {t('resetpassword.content.title')}
      </SisuPageHeader>
      <SisuPageSubHeader className={s['subheader']}>
        {t('resetpassword.content.subheader')}
      </SisuPageSubHeader>
      <SisuPageContent className={s['content']}>
        <SisuForm
          action={`/_sisu/do/reset_password`}
          method="post"
          onSubmit={onFormSubmit}
        >
          <input type="hidden" name="username" value={username} />
          <input type="hidden" name="passkey" value={passkey} />

          <PasswordInput
            id="password-input"
            name="password"
            label={t('resetpassword.form.password')}
            value={password}
            onChange={ev => setPassword(ev.target.value)}
          />
          <PasswordInput
            id="password-confirmation-input"
            name="password_confirmation"
            label={t('resetpassword.form.password_confirmation')}
            value={passwordConfirmation}
            onChange={ev => setPasswordConfirmation(ev.target.value)}
            validationResult={passwordError ? 'error' : undefined}
            details={passwordError}
          />
          <Button
            aria-label={t('resetpassword.form.submit')}
            className={s['submit']}
            type="submit"
            view="b1-1"
            fullWidth={false}
          >
            {t('resetpassword.form.submit')}
          </Button>
        </SisuForm>
      </SisuPageContent>
      <SisuPageFooter className={s['footer']}>
        <Trans i18nKey="resetpassword.content.footer">
          Having trouble recovering your password?
          <br />
          Please contact the <a href={URLS.HELP}>Help Desk</a>.
        </Trans>
      </SisuPageFooter>
    </SisuPage>
  );
};
ResetPasswordPage.displayName = 'ResetPasswordPage';

export default ResetPasswordPage;
