export const PERMISSION_LABEL_KEYS = {
  basic: ['oauth.permission.user', 'oauth.permission.stash'],
  user: ['oauth.permission.user'],
  stash: ['oauth.permission.stash'],
  email: ['oauth.permission.email'],
  publish: ['oauth.permission.publish'],
  group: ['oauth.permission.group'],
  browse: ['oauth.permission.browse'],
  collection: ['oauth.permission.collection'],
  comment_manage: ['oauth.permission.comment_manage'],
  comment_post: ['oauth.permission.comment_post'],
  feed: ['oauth.permission.feed'],
  gallery: ['oauth.permission.gallery'],
  message: ['oauth.permission.message'],
  note: ['oauth.permission.note'],
  user_manage: ['oauth.permission.user_manage'],
  poll: ['oauth.permission.poll'],
  cart: ['oauth.permission.cart'],
  deviation_manage: ['oauth.permission.deviation_manage'],
  contest: ['oauth.permission.contest'],
};
