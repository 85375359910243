// DS: PO58
/**
 * Topics onboarding, with suggestions driven by the Neco backend
 *
 * The backend expects a full history of suggestions it's provided, in
 * order to generate a new batch of suggestions while doing its best
 * to exclude what's already been suggested
 */
import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiTopic } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import Grid from '@wix/da-shared-react/pkg/Grid';
import OnboardingPage from '../../SignupSignin/OnboardingPage';
import OnboardingTopicCard from './OnboardingTopicCard';

import s from './TopicsPage.scss';

export interface Props {
  referer?: string;
  /** The list of topics currently displayed */
  allTopics?: PapiTopic[];
  /** The topic IDs newly added as suggestions */
  suggestedTopicIds: number[];
  /** The history of all IDs ever displayed */
  topicIdHistory: number[][];
  /** Number of topics currently displayed, for prop comparison */
  topicCount: number;
  /** The IDs of topics currently selected, no history required */
  selectedTopicIds: number[];
  /** Number of topics currently selected, for prop comparison */
  selectedTopicCount: number;
  topicSelect(selectionHistory, itemId): void;
  topicUnselect(selectionHistory, itemId): void;
  topicSuggestions(selectionHistory): void;
}

const PLACEHOLDER_COUNT = 15;
const MIN_CHOSEN_TOPICS = 3;

const TOPIC_SPACING = parseInt(s['grid-spacing']) || 24;
const DESKTOP_GRID_BREAKPOINTS = [
  {
    maxWidth: 9999,
    elementsPerRow: 5,
    elementAspectRatio: 1,
  },
];
const MOBILE_TOPIC_SPACING = parseInt(s['mobile-grid-spacing']) || 16;
const MOBILE_GRID_BREAKPOINTS = [
  {
    maxWidth: 767,
    elementsPerRow: 3,
    elementAspectRatio: 1,
  },
];

export const TopicsPage: React.FC<Props> = ({
  referer,
  selectedTopicIds,
  suggestedTopicIds,
  topicIdHistory,
  allTopics = [],
  topicSelect,
  topicUnselect,
  topicSuggestions,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  useEffectOnceOnMount(() => topicSuggestions([]));

  // useCallback worth it here since new fns would render the entire grid again
  const navigateToReferer = useCallback(() => {
    typeof window !== 'undefined' &&
      window.location.assign(referer || Url.origin());
  }, [referer]);

  const renderElement = useCallback(
    ({ width, index }) => {
      const topic = allTopics[index];
      return (
        <OnboardingTopicCard
          key={topic.name}
          className={classnames(
            s['topic'],
            topicIdHistory.length > 1 &&
              suggestedTopicIds &&
              suggestedTopicIds.includes(topic.itemId) &&
              s[`topic-new-${suggestedTopicIds.indexOf(topic.itemId)}`]
          )}
          typeId={topic.typeId}
          itemId={topic.itemId}
          name={topic.name}
          coverDeviation={topic.exampleDeviations && topic.exampleDeviations[0]}
          width={width}
          rank={index}
          isSelected={
            selectedTopicIds && selectedTopicIds.includes(topic.itemId!)
          }
          onClick={(name: string, itemId?: number) => {
            if (!itemId) {
              return;
            }

            if (selectedTopicIds.includes(itemId)) {
              topicUnselect(topicIdHistory, itemId);
            } else {
              topicSelect(topicIdHistory, itemId);
            }
          }}
        />
      );
    },
    [
      selectedTopicIds,
      suggestedTopicIds,
      topicIdHistory,
      allTopics,
      topicSelect,
      topicUnselect,
    ]
  );

  const topicsHaveLoaded = allTopics.length > 0;
  const canContinue =
    topicsHaveLoaded && selectedTopicIds.length >= MIN_CHOSEN_TOPICS;

  return (
    <OnboardingPage
      pageTitle={t('onboarding.topics.page.title')}
      heading={t('onboarding.topics.heading')}
      subheading={t('onboarding.topics.subheading')}
      canContinue={canContinue}
      // topics page is last stop
      onContinue={navigateToReferer}
    >
      {topicsHaveLoaded ? (
        <Grid
          containerClassName={s['topics-grid-container']}
          className={s['topics-grid']}
          cookieType={MeasuredCookieType.SCREEN_WIDTH}
          elementCount={allTopics.length}
          spacing={isMobile ? MOBILE_TOPIC_SPACING : TOPIC_SPACING}
          breakpoints={
            isMobile ? MOBILE_GRID_BREAKPOINTS : DESKTOP_GRID_BREAKPOINTS
          }
          getElementId={String}
          renderElement={renderElement}
        />
      ) : (
        <div className={s['topics-placeholder-grid']}>
          {Array.from({ length: PLACEHOLDER_COUNT }).map((_, i) => (
            <div key={`placeholder-${i}`} className={s['topic-placeholder']} />
          ))}
        </div>
      )}
    </OnboardingPage>
  );
};

export default TopicsPage;
