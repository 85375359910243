import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ForgotPageProps } from './ForgotPage';
import { AppState } from '../../../types/store';
import { changeUsername } from '../../../actions/forgot';
import { getForgotUsername, getForgotKind } from '../../../selectors/forgot';

type StateProps = Pick<
  ForgotPageProps,
  | 'username'
  | 'kind'
  | 'usernameError'
  | 'generalError'
  | 'emailSent'
  | 'daProtect'
>;
type DispatchProps = Pick<ForgotPageProps, 'changeUsername'>;
type OwnProps = Omit<ForgotPageProps, keyof (StateProps & DispatchProps)> &
  RouteComponentProps;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { location }
) => {
  return {
    ...state.forgot,
    username: getForgotUsername(state, location),
    kind: getForgotKind(state, location),
    daProtect: state.params.daProtect,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  changeUsername,
};

export default connect(mapStateToProps, mapDispatchToProps);
