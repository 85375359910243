import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Props } from './TFAVerificationPage';
import { AppState } from '../../../types/store';

type StateProps = Pick<
  Props,
  'user' | 'remember' | 'isVerificationCodeInvalid' | 'isRecoveryCodeInvalid'
>;
type OwnProps = Omit<Props, keyof StateProps> &
  RouteComponentProps<{ username?: string; passkey?: string }>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState
) => {
  return {
    user: state.login.tfaUserData!, // we know it's truthy, otherwise this view wouldn't be called
    remember: state.login.remember,
    isVerificationCodeInvalid: state.login.tfaVerificationCodeInvalid,
    isRecoveryCodeInvalid: state.login.tfaRecoveryCodeInvalid,
  };
};

export default connect(mapStateToProps);
