import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  BiData,
  SignupOnSignupClickBiEvent,
  SignupScreenClickContinueBiEvent,
} from '@wix/da-bi/pkg/events';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import { SocialLoginProvider } from '@wix/da-shared-react/pkg/SocialLogin/types';
import SocialButtonApple from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonApple';
import SocialButtonFacebook from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonFacebook';
import SocialButtonGoogle from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonGoogle';
import DateOfBirth from '../DateOfBirth';
import SisuForm from '../SisuForm';
import JoinState from '../../../types/join';
import { getAuthMode } from '../../../helpers/bi';
import SocialLoginDivider from '../SocialLoginDivider';

import s from './UsernameForm.scss';

export interface Props extends JoinState {
  isOauth?: boolean;
  changeUsername(username: string): void;
  changeDateOfBirth(type: 'day' | 'month' | 'year', value: number): void;
  attemptSubmit(): void;
}

const UsernameForm: React.FC<Props> = ({
  joinMode,
  joinpoint,
  isOauth,
  email,
  password,
  username,
  usernameError,
  usernameFetching,
  showUsernameAvailable,
  dobDay,
  dobMonth,
  dobYear,
  dobError,
  socialLoginProvider,
  socialLoginTokenId,
  changeUsername,
  changeDateOfBirth,
  attemptSubmit,
}) => {
  const { t } = useTranslation();
  const biLogger = useBiLogger();
  const isMobile = useContext(MobileContext);

  const errors = {
    username:
      usernameError &&
      t([
        `join.error.username.${usernameError}`,
        'join.error.username.invalid',
      ]),
    dob:
      dobError && t([`join.error.dob.${dobError}`, 'join.error.dob.invalid']),
  };
  const onSubmit = (e: React.FormEvent) => {
    attemptSubmit();
    if (
      !username ||
      usernameError ||
      !dobDay ||
      !dobMonth ||
      !dobYear ||
      dobError
    ) {
      e.preventDefault();
    } else {
      biLogger(
        BiData<SignupOnSignupClickBiEvent>({
          evid: 247,
          auth_mode:
            joinMode === 'social'
              ? getAuthMode(socialLoginProvider)
              : undefined,
        })
      );
    }
  };

  let joinSections = [(
    <React.Fragment key="form">
      <div className={s['username']}>
        <TextInput
          id="username"
          name="username"
          label={t('join.form.username')}
          value={username || ''}
          validationResult={
            usernameError
              ? 'error'
              : showUsernameAvailable
              ? 'success'
              : undefined
          }
          details={errors.username}
          className={c(s['loading'], usernameFetching && s['loading'])}
          autoFocus
          spellCheck={false}
          autoComplete="off"
          autoCapitalize="none"
          onChange={ev => changeUsername(ev.target.value.trim())}
          noBottomSpacing
        />
      </div>
      <DateOfBirth
        label={t('join.form.dob')}
        monthValue={dobMonth || undefined}
        dayValue={dobDay || undefined}
        yearValue={dobYear || undefined}
        onSelect={changeDateOfBirth}
        errorMessage={errors.dob ? errors.dob : undefined}
        className={s['dob']}
      />
      <BrandedButton
        // id="joinbutton"
        className={s['button']}
        size="large"
        variant="brand-green"
        type="submit"
        fillContainerWidth
      >
        {t('join.form.submit')}
      </BrandedButton>
    </React.Fragment>
  ),(
    <SocialLoginDivider key="divider" className={s['divider']} />
  ),(
    <React.Fragment key="social">
      <SocialButtonGoogle
        className={s['social-button']}
        biData={BiData<SignupScreenClickContinueBiEvent>({
          evid: 238,
          page_number: 2,
          auth_mode: getAuthMode(SocialLoginProvider.Google),
        })}
      />
      <SocialButtonApple
        className={s['social-button']}
        biData={BiData<SignupScreenClickContinueBiEvent>({
          evid: 238,
          page_number: 2,
          auth_mode: getAuthMode(SocialLoginProvider.Apple),
        })}
      >
        {t('join.form.button.continue.apple')}
      </SocialButtonApple>
      <SocialButtonFacebook
        className={s['social-button']}
        biData={BiData<SignupScreenClickContinueBiEvent>({
          evid: 238,
          page_number: 2,
          auth_mode: getAuthMode(SocialLoginProvider.Facebook),
        })}
      >
        {t('join.form.button.continue.facebook')}
      </SocialButtonFacebook>
    </React.Fragment>
  )];
  if (joinMode !== 'email') {
    joinSections = [joinSections[0]];
  }
  if (!isMobile) {
    joinSections.reverse();
  }

  return (
    <SisuForm action="/_sisu/do/signup2" method="post" onSubmit={onSubmit}>
      <input type="hidden" name="join_mode" value={joinMode} />
      <input type="hidden" name="oauth" value={isOauth ? 1 : 0} />
      {joinpoint && <input type="hidden" name="joinpoint" value={joinpoint} />}
      <input type="hidden" name="email" value={email} />
      <input type="hidden" name="password" value={password} />
      <input type="hidden" name="token_id" value={socialLoginTokenId} />
      {joinSections}
    </SisuForm>
  );
};
UsernameForm.displayName = 'UsernameForm';

export default UsernameForm;
