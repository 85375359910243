import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import { Props } from './TFAReminderPage';

type StateProps = Pick<Props, 'referer'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState
) => {
  return { referer: state.referer };
};

export default connect(mapStateToProps);
