import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Logo from '@wix/da-ds/pkg/Logo';
import Button from '@wix/da-shared-react/pkg/Button';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import {
  ThemeOptions,
  ThemeOverride,
} from '@wix/da-react-context/pkg/ThemeContext';
import { PapiDeviation } from '@wix/da-papi-types';
import JoinPage from '../JoinPage';
import {
  withMobileContext,
  WithMobileContextProps,
} from '@wix/da-react-context/pkg/MobileContext';
import styles from './StartPage.scss';

export interface StartPageProps {
  cardImageUrl?: string;
  cardDeviation?: PapiDeviation;
}

class StartPage extends React.Component<
  StartPageProps & WithTranslation & WithMobileContextProps
> {
  render() {
    if (!this.props.isMobile) {
      return <JoinPage />;
    }

    return this.renderMobileStart();
  }

  renderMobileStart() {
    const { cardDeviation, t } = this.props;
    return (
      <div
        className={styles['root']}
        style={{ backgroundImage: `url(${this.props.cardImageUrl})` }}
      >
        <div className={styles['overlay']}>
          <div className={styles['header']}>
            <Logo textColor="white" className={styles['logo']} size="tiny" />
            <ThemeOverride theme={ThemeOptions.DARK}>
              <Button
                view="b14"
                className={styles['login-button']}
                href="/users/login"
              >
                {t('start.login')}
              </Button>
            </ThemeOverride>
          </div>
          <div className={styles['content']}>
            <div className={styles['headline']}>{t('start.headline')}</div>
            <div className={styles['notdeviant']}>{t('start.notdeviant')}</div>
            <Button className={styles['join-button']} href="/join/">
              {t('start.join')}
            </Button>
          </div>
          {cardDeviation && cardDeviation.author && (
            <div className={styles['artist-name']}>
              <span>{t('start.artist')}</span>{' '}
              <UserLink
                className={styles['artist-name-link']}
                user={cardDeviation.author}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withMobileContext(withTranslation()(StartPage));
