import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { PapiTopic } from '@wix/da-papi-types';
import { requestPuppy } from '@wix/da-http-client';
import * as selectors from './selectors';
import {
  TOPIC_SELECT,
  TOPIC_UNSELECT,
  TOPIC_SUGGESTIONS,
  topicListUpdate,
} from '../actions/topic';

function mergeTopics(
  oldList: PapiTopic[],
  newList: PapiTopic[],
  insertAfter?: number
) {
  const newIds = newList.map(topic => topic.itemId || 0);
  if (!oldList || !oldList.length) {
    return {
      availableTopics: newList,
      newTopicIds: newIds,
    };
  }

  const oldIds: number[] = oldList.map(topic => topic.itemId || 0);

  newList = newList.filter(topic => !oldIds.includes(topic.itemId || 0));

  if (insertAfter && oldIds.indexOf(insertAfter) !== -1) {
    oldList.splice(oldIds.indexOf(insertAfter) + 1, 0, ...newList);
  } else {
    oldList.splice(-1, 0, ...newList);
  }
  return {
    availableTopics: oldList,
    newTopicIds: newIds,
  };
}

export function* topicWatch(action) {
  const prevTopics = yield select(selectors.availableTopics);
  const result = yield call(requestPuppy, {
    method: 'post',
    url: '/topic/watch',
    data: {
      necoid: action.newTopicId,
      suggested_neco_id_lists: JSON.stringify(action.topicIdHistory),
      source: 'onboarding',
    },
  });
  if (result && result.suggestions) {
    const mergedResult = mergeTopics(
      prevTopics,
      result.suggestions,
      action.newTopicId
    );
    yield put(
      topicListUpdate(mergedResult.availableTopics, mergedResult.newTopicIds)
    );
  }
}

export function* topicUnwatch(action) {
  yield call(requestPuppy, {
    method: 'post',
    url: '/topic/unwatch',
    data: {
      necoid: action.oldTopicId,
      suggested_neco_id_lists: JSON.stringify(action.topicIdHistory),
      source: 'onboarding',
    },
  });
}

export function* topicSuggestions(action) {
  const prevTopics = yield select(selectors.availableTopics);
  const result = yield call(requestPuppy, {
    method: 'post',
    url: '/topic/suggestions',
    data: {
      suggested_neco_id_lists: JSON.stringify(action.topicIdHistory),
      source: 'onboarding',
    },
  });
  if (result && result.suggestions) {
    const mergedResult = mergeTopics(prevTopics, result.suggestions);
    yield put(
      topicListUpdate(mergedResult.availableTopics, mergedResult.newTopicIds)
    );
  }
}

export default function* topicSaga() {
  yield all([
    takeLatest(TOPIC_SELECT, topicWatch),
    takeLatest(TOPIC_UNSELECT, topicUnwatch),
    takeLatest(TOPIC_SUGGESTIONS, topicSuggestions),
  ]);
}
