import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import DaDreamupLogoAndText from '@wix/da-ds/pkg/DaDreamupLogoAndText';
import { PapiDeviation } from '@wix/da-papi-types';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import DaProtectLogo from '@wix/da-ds/pkg/DaProtectLogo';
import SisuPageLogo from '../SisuPageLogo';
import s from './SisuPageHero.scss';

export interface Props {
  cardImageUrl?: string;
  cardDeviation?: PapiDeviation;
  baseDaUrl: string;
  heroTextKey?: string;
  withLogo?: boolean;
  withLogoLink?: boolean;
  className?: string;
  daProtect?: boolean;
  daDreamup?: boolean;
}

const SisuPageHero: React.FC<Props> = ({
  cardDeviation,
  cardImageUrl,
  baseDaUrl,
  heroTextKey,
  withLogo,
  withLogoLink,
  className,
  daProtect,
  daDreamup,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={c(s['hero'], className, daProtect && s['protect'])}
      style={{
        backgroundImage: `url(${
          // TODO: Pull from a list of dreamup deviations on the backend instead?
          daDreamup
            ? 'https://st.deviantart.net/eclipse/sisu/dreamupv2.png'
            : cardImageUrl
        })`,
      }}
    >
      <div className={s['hero-overlay']}>
        <div>
          {withLogo &&
            (daProtect ? (
              <DaProtectLogo className={s['protect-logo']} />
            ) : daDreamup ? (
              <DaDreamupLogoAndText className={s['dreamup-logo']} />
            ) : (
              <SisuPageLogo
                withLink={withLogoLink}
                baseDaUrl={baseDaUrl}
                className={s['inner-logo']}
              />
            ))}
          <h1 className={s['hero-bold-text']}>
            {t([
              `${heroTextKey}.communitycard.heading`,
              'communitycard.heading',
            ])}
          </h1>
          <p className={s['hero-sub-text']}>
            {t([`${heroTextKey}.communitycard.body`, 'communitycard.body'])}
          </p>
        </div>
        {!daDreamup && cardDeviation && cardDeviation.author && (
          <div className={s['attribution']}>
            <div className={s['artby']}>
              {t([
                `${heroTextKey}.communitycard.attribution`,
                'communitycard.attribution',
              ])}
            </div>
            <UserLink className={s['username']} user={cardDeviation.author} />
          </div>
        )}
        {daDreamup && (
          <div className={s['attribution']}>
            <div className={s['artby']}>
              {t('dreamup.communitycard.attribution.label')}
            </div>
            <div className={s['username']}>
              {t('dreamup.communitycard.attribution.author')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
SisuPageHero.displayName = 'SisuPageHero';

export default SisuPageHero;
