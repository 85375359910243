import { combineReducers } from 'redux';
import login from './login';
import forgot from './forgot';
import join from './join';
import topic from './topic';
import oauth from './oauth';
import authMode from './authMode';
import resetPassword from './resetPassword';
import flagsReducer from '@wix/da-react-context/pkg/flags/redux/reducer';
import publicSessionReducers from '@wix/da-shared-react/pkg/publicSession/reducers';
import modalsReducers from '@wix/da-shared-react/pkg/Modals/redux/reducer';
import sharedConfigReducers from '@wix/da-redux-config/pkg/reducers';
import notificationReducers from '@wix/da-shared-react/pkg/Notifications/redux/reducer';
import socialLoginReducers from '@wix/da-shared-react/pkg/SocialLogin/redux/reducer';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, action) =>
    state;

const rootReducerMap = {
  login,
  forgot,
  join,
  topic,
  oauth,
  authMode,
  resetPassword,
  params: buildFakeReducer({ oauth: {} }),
  rootPath: buildFakeReducer(''),
  cardImage: buildFakeReducer(''),
  cardDeviation: buildFakeReducer(null),
  requestId: buildFakeReducer(''),
  referer: buildFakeReducer(''),
  headerReferer: buildFakeReducer(''),
  refererType: buildFakeReducer(''),
  baseDaUrl: buildFakeReducer(''),
  csrfToken: buildFakeReducer(''),
  luToken: buildFakeReducer(''),
  luToken2: buildFakeReducer(''),
  isMobile: buildFakeReducer(false),
  isDebug: buildFakeReducer(false),
  isTestEnv: buildFakeReducer(false),
  recaptchaSiteKey: buildFakeReducer(''),
  environmentType: buildFakeReducer('prod'),
  isOauthError: buildFakeReducer(false),
  appRefreshIsHardRefresh: buildFakeReducer(false),
  appRefreshIsLoginRedirect: buildFakeReducer(false),
  ...flagsReducer,
  ...publicSessionReducers,
  ...modalsReducers,
  ...sharedConfigReducers,
  ...notificationReducers,
  ...socialLoginReducers,
};

const rootReducer = combineReducers(rootReducerMap);

export { rootReducerMap };
export default rootReducer;
