import { connect, MapStateToProps } from 'react-redux';
import { StartPageProps } from './StartPage';
import { AppState } from '../../../types/store';

type StateProps = Pick<StartPageProps, 'cardDeviation' | 'cardImageUrl'>;
type OwnProps = Omit<StartPageProps, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  cardImageUrl: state.cardImage,
  cardDeviation: state.cardDeviation,
});

export default connect(mapStateToProps);
