import { createAction } from 'redux-actions';

import { BI_SISU_EVENT, AuthMode } from './bi';
import {
  BiData,
  LoginErrorBiEvent,
  LoginForgotPasswordClickBiEvent,
  LoginRememberClickBiEvent,
  LoginScreenShowBiEvent,
  LoginScreenSignupClickBiEvent,
  LoginScreenSubmitBiEvent,
  LoginSuccessBiEvent,
  PolicyClickBiEvent,
  RedirectToLoginBiEvent,
  TermsClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';

export const screenLoaded = createAction(
  BI_SISU_EVENT,
  (isChallenge: boolean = false) =>
    BiData<LoginScreenShowBiEvent>({
      evid: 201,
      user_name_challenge_mode: isChallenge,
    })
);

export const joinClicked = createAction(BI_SISU_EVENT, () =>
  BiData<LoginScreenSignupClickBiEvent>({
    evid: 244,
  })
);

export const submitClicked = createAction(
  BI_SISU_EVENT,
  (isChallenge: boolean = false) =>
    BiData<LoginScreenSubmitBiEvent>({
      evid: 202,
      user_name_challenge_mode: isChallenge,
    })
);

export const rememberClicked = createAction(
  BI_SISU_EVENT,
  (newState: boolean, isChallenge: boolean = false) =>
    BiData<LoginRememberClickBiEvent>({
      evid: 204,
      new_state: newState ? 1 : 0,
      user_name_challenge_mode: isChallenge,
    })
);

export const forgotClicked = createAction(
  BI_SISU_EVENT,
  (isChallenge: boolean = false) =>
    BiData<LoginForgotPasswordClickBiEvent>({
      evid: 205,
      user_name_challenge_mode: isChallenge,
    })
);

export const termsClicked = createAction(
  BI_SISU_EVENT,
  (isChallenge: boolean = false) =>
    BiData<TermsClickBiEvent>({
      evid: 206,
      user_name_challenge_mode: isChallenge,
    })
);

export const policyClicked = createAction(
  BI_SISU_EVENT,
  (isChallenge: boolean = false) =>
    BiData<PolicyClickBiEvent>({
      evid: 207,
      user_name_challenge_mode: isChallenge,
    })
);

export const errorDisplayed = createAction(
  BI_SISU_EVENT,
  (errorType: string, errorMessage: string, isChallenge: boolean = false) =>
    BiData<LoginErrorBiEvent>({
      evid: 203,
      error_message: errorMessage,
      error_type: errorType,
      user_name_challenge_mode: isChallenge,
    })
);

export const loginSuccess = createAction(BI_SISU_EVENT, (authMode: AuthMode) =>
  BiData<LoginSuccessBiEvent>({
    evid: 211,
    is_new_user: false,
    auth_mode: authMode,
  })
);

export const redirectToLogin = createAction(BI_SISU_EVENT, () =>
  BiData<RedirectToLoginBiEvent>({
    evid: 240,
    referral_info: ReferralType.LOGIN,
  })
);
