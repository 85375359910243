import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Checkbox from '@wix/da-ds/pkg/Checkbox';

import s from './IntentCard.scss';

interface Intent {
  backendId: number;
  imgName: string;
}

type IntentMap = Record<string, Intent>;

// backend ids are defined here: vms/lib/join/v./lib.join.php
// they won't likely change - so just I duplicated them here
// unused backendIds: promote: 3, other: 0
export const INTENTS: IntentMap = {
  discover: {
    backendId: 1,
    imgName: 'il4.2_discover',
  },
  community: {
    backendId: 6,
    imgName: 'il4.23_connect_with_community',
  },
  buy: {
    backendId: 5,
    imgName: 'il4.1_buy_art',
  },
  improve: {
    backendId: 2,
    imgName: 'il4.3_improve_skills',
  },
  ai: {
    backendId: 7,
    imgName: 'il4.24_create_ai_art',
  },
  sell: {
    backendId: 4,
    imgName: 'il4.6_sell_my_art',
  },
};

export interface Props {
  intentId: string;
  inputName: string;
  selected: boolean;
  className?: string;
  onClick: (id: string) => void;
}

export const IntentCard: React.FC<Props> = ({
  intentId,
  inputName,
  selected,
  className,
  onClick,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const intent: Intent = INTENTS[intentId];
  if (!intent) {
    return null;
  }

  const { imgName, backendId } = intent;
  const imgSize = isMobile ? '80px' : '120px';
  const imgCDNPath = Url.staticCDNLink(
    `eclipse/sisu/${imgName}_${imgSize}.png`
  );

  const titleId = `${intentId}_title`;
  return (
    <>
      <button
        type="button"
        role="option"
        aria-selected={selected ? 'true' : 'false'}
        aria-labelledby={titleId}
        className={classnames(
          className,
          selected && 'ds-card-selected-thick',
          'ds-card',
          s['root']
        )}
        onClick={() => onClick(intentId)}
      >
        <div className={s['corner']}>
          <Checkbox
            className={s['corner-mark']}
            isChecked={selected}
            tabIndex={-1}
          />
        </div>

        <img className={s['illustration']} src={imgCDNPath} alt="_" />
        <div id={titleId}>{t(`onboarding.intentsCard.title.${intentId}`)}</div>
      </button>

      {selected && <input type="hidden" name={inputName} value={backendId} />}
    </>
  );
  // }
};

export default IntentCard;
