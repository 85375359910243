import ForgotState from '../types/forgot';
import { FORGOT_CHANGE_USERNAME } from '../actions/forgot';
import { APP_REFRESH } from '../actions/app';

const defaultState: ForgotState = {
  username: '',
  emailSent: false,
};

export default function forgot(state = defaultState, action): ForgotState {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true };
  }

  switch (action.type) {
    case FORGOT_CHANGE_USERNAME:
      return changeUsername(state, action);
    case APP_REFRESH:
      return appRefresh(state);
    default:
      return state;
  }
}

function changeUsername(state: ForgotState, action) {
  return {
    ...state,
    username: action.username,
    usernameError: undefined,
  };
}

function appRefresh(state: ForgotState) {
  return {
    ...state,
    ...defaultState,
  };
}
