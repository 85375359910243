import React from 'react';
import classnames from 'classnames';
import s from './SisuPage.scss';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const SisuPageContent: React.FC<Props> = ({ className, children }) => {
  return <div className={classnames(s['content'], className)}>{children}</div>;
};
SisuPageContent.displayName = 'SisuPageContent';

export default SisuPageContent;
