import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getErrorCode } from '@wix/da-shared-react/pkg/SocialLogin/redux/selectors';
import { Props } from './JoinPage';
import { AppState } from '../../../types/store';
import {
  loginClicked,
  screenLoaded,
  termsClicked,
  policyClicked,
} from '../../../actions/join';
import {
  getKnownUrlParams,
  getKnownUrlParamsWithHeaderReferer,
} from '../../../selectors/page';

type StateProps = Pick<
  Props,
  | 'urlParams'
  | 'urlParamsWithHeaderReferer'
  | 'generalError'
  | 'socialLoginError'
  | 'daProtect'
>;
type DispatchProps = Pick<
  Props,
  'loginClicked' | 'screenLoaded' | 'termsClicked' | 'policyClicked'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  urlParams: getKnownUrlParams(state),
  urlParamsWithHeaderReferer: getKnownUrlParamsWithHeaderReferer(state),
  refererType: state.refererType,
  generalError: state.join.generalError,
  socialLoginError: getErrorCode(state),
  daProtect: state.params.daProtect,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  loginClicked,
  screenLoaded,
  termsClicked,
  policyClicked,
};

export default connect(mapStateToProps, mapDispatchToProps);
