import { connect, MapStateToProps } from 'react-redux';
import { getCsrfToken } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { Props } from './LogoutOauthPage';

type StateProps = Pick<Props, 'csrfToken'>;
type DispatchProps = Pick<Props, never>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, {}> = state => ({
  csrfToken: getCsrfToken(state),
});

export default connect(mapStateToProps);
