import React from 'react';

export interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  referer?: string;
  refererType?: string;
  csrfToken?: string;
  rootPath?: string;
  daProtect?: boolean;
  daDreamup?: boolean;
  formRef?: (node: HTMLFormElement | null) => void;
  action: string;
}

const SisuForm: React.FC<Props> = ({
  referer,
  refererType,
  csrfToken,
  action,
  rootPath,
  children,
  daProtect,
  daDreamup,
  formRef,
  ...restProps
}) => {
  return (
    <form action={rootPath + action} ref={formRef} {...restProps}>
      <input type="hidden" name="referer" value={referer} />
      <input type="hidden" name="referer_type" value={refererType} />
      <input type="hidden" name="csrf_token" value={csrfToken} />
      {daProtect && <input type="hidden" name="da_protect" value={1} />}
      {daDreamup && <input type="hidden" name="da_dreamup" value={1} />}
      {children}
    </form>
  );
};

export default SisuForm;
