import React from 'react';
import classnames from 'classnames';
import s from './SisuPage.scss';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const SisuPageHeader: React.FC<Props> = ({ className, children }) => {
  return <h2 className={classnames(s['header'], className)}>{children}</h2>;
};
SisuPageHeader.displayName = 'SisuPageHeader';

export default SisuPageHeader;
