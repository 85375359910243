import { AppState } from '../types/store';

export function csrfToken(state: AppState) {
  return state.csrfToken;
}

export function referer(state: AppState) {
  return state.referer;
}

export function refererType(state: AppState) {
  return state.refererType;
}

export function availableTopics(state: AppState) {
  return state.topic.available;
}
