import { createAction } from 'redux-actions';
import JoinState from '../types/join';

export const JOIN_CHANGE_USERNAME = 'JOIN_CHANGE_USERNAME';
export const JOIN_CHANGE_PASSWORD = 'JOIN_CHANGE_PASSWORD';
export const JOIN_CHANGE_PASSWORD2 = 'JOIN_CHANGE_PASSWORD2';
export const JOIN_CHANGE_EMAIL = 'JOIN_CHANGE_EMAIL';
export const JOIN_VALIDATE_EMAIL = 'JOIN_VALIDATE_EMAIL';
export const JOIN_CHANGE_EMAIL2 = 'JOIN_CHANGE_EMAIL2';
export const JOIN_VALIDATE_CONFIRM_EMAIL = 'JOIN_VALIDATE_CONFIRM_EMAIL';
export const JOIN_CHANGE_DOB = 'JOIN_CHANGE_DOB';
export const JOIN_FETCH_USERNAME_AVAILABLE = 'JOIN_FETCH_USERNAME_AVAILABLE';
export const JOIN_CHANGE_USERNAME_AVAILABLE = 'JOIN_CHANGE_USERNAME_AVAILABLE';
export const JOIN_CHANGE_STEP = 'JOIN_CHANGE_STEP';
export const JOIN_ATTEMPT_SUBMIT = 'JOIN_ATTEMPT_SUBMIT';

export function changeUsername(username) {
  return { type: JOIN_CHANGE_USERNAME, username };
}

export function changePassword(password) {
  return { type: JOIN_CHANGE_PASSWORD, password };
}

export function changePassword2(password) {
  return { type: JOIN_CHANGE_PASSWORD2, password };
}

export function changeEmail(email) {
  return { type: JOIN_CHANGE_EMAIL, email };
}

export function validateEmail(email) {
  return { type: JOIN_VALIDATE_EMAIL, email };
}

export function changeEmail2(email) {
  return { type: JOIN_CHANGE_EMAIL2, email };
}

export function validateConfirmEmail(email: string, confirmEmail: string) {
  return { type: JOIN_VALIDATE_CONFIRM_EMAIL, email, confirmEmail };
}

export function changeDateOfBirth(part, value) {
  return { type: JOIN_CHANGE_DOB, part, value };
}

export function changeStep(step) {
  return { type: JOIN_CHANGE_STEP, step };
}

export function fetchUsernameAvailable() {
  return { type: JOIN_FETCH_USERNAME_AVAILABLE };
}

export function changeUsernameAvailable(available, username) {
  return { type: JOIN_CHANGE_USERNAME_AVAILABLE, available, username };
}

export function attemptSubmit() {
  return { type: JOIN_ATTEMPT_SUBMIT };
}

export const joinWithEmail = createAction('JOIN.WITH_EMAIL', () => ({}));

export const setJoinMode = createAction(
  'JOIN.SET_MODE',
  (mode: JoinState['joinMode']) => ({ mode })
);

export const attemptSubmit2 = createAction('JOIN.ATTEMPT.SUBMIT.2', () => ({}));

export * from './bi/join.bi';
