import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import Button from '@wix/da-shared-react/pkg/Button';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { BiData, TfaScreenShownBiEvent } from '@wix/da-bi/pkg/events';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
} from '../SisuPage';
import SisuForm from '../../SignupSignin/SisuForm';
import MiniUserDisplay from './_partials/MiniUserDisplay';

import s from './TFAVerificationPage.scss';

const CONTACT_SUPPORT_LINK =
  'https://www.deviantartsupport.com/en/article/what-should-i-do-if-ive-lost-access-to-my-account';

export interface Props {
  user: PapiUser;
  remember?: boolean;
  isVerificationCodeInvalid?: boolean;
  isRecoveryCodeInvalid?: boolean;
}

const TFAVerificationPage: React.FC<Props> = ({
  user,
  remember,
  isVerificationCodeInvalid,
  isRecoveryCodeInvalid,
}) => {
  const { t } = useTranslation();
  const logEvent = useBiLogger();
  const [isRecoveryEnabled, setRecoveryEnabled] = useState(
    isRecoveryCodeInvalid || false
  );
  const [recoveryCode, setRecoveryCode] = useState<string | undefined>();
  const [verificationCode, setVerificationCode] = useState<
    string | undefined
  >();
  
  useEffect(() => {
    logEvent(BiData<TfaScreenShownBiEvent>({ evid: 239 }));
  });

  return (
    <SisuPage
      className={s['root']}
      bodyClassName={s['body']}
      title={t('tfa.page.title')}
      origin="/users/login"
      errorTextKey="login"
    >
      <SisuPageHeader className={s['header']}>
        {t('tfa.content.heading')}
      </SisuPageHeader>
      <MiniUserDisplay user={user} />
      {!isRecoveryEnabled && (
        <SisuPageSubHeader className={s['subheader']}>
          {t('tfa.content.use_verification_code')}
        </SisuPageSubHeader>
      )}
      <SisuPageContent className={s['content']}>
        <SisuForm action={`/_sisu/do/signin_tfa`} method="post">
          <input type="hidden" name="username" value={user.username} />
          <input type="hidden" name="remember" value={remember ? 1 : 0} />
          {isRecoveryEnabled ? (
            <TextInput
              id="tfa-code-input"
              name="recovery_code"
              label={t(`tfa.form.recovery_code`)}
              validationResult={isRecoveryCodeInvalid ? 'error' : undefined}
              details={
                isRecoveryCodeInvalid &&
                t('tfa.error.invalid_recovery_key')
              }
              value={recoveryCode || ''}
              onChange={ev => setRecoveryCode(ev.target.value)}
              autoFocus
            />
          ) : (
            <TextInput
              id="tfa-code-input"
              type="number"
              name="verification_code"
              label={t(`tfa.form.verification_code`)}
              validationResult={isVerificationCodeInvalid ? 'error' : undefined}
              details={
                isVerificationCodeInvalid &&
                t('tfa.error.invalid_verification_code')
              }
              value={verificationCode || ''}
              onChange={ev => setVerificationCode(ev.target.value)}
              autoFocus
            />
          )}
          <Button
            aria-label={
              isRecoveryEnabled
                ? t('tfa.form.recovery_submit')
                : t('tfa.form.verification_submit')
            }
            className={s['submit']}
            type="submit"
            view="b1-1"
            fullWidth={false}
          >
            {isRecoveryEnabled
              ? t('tfa.form.recovery_submit')
              : t('tfa.form.verification_submit')}
          </Button>
        </SisuForm>

        {isRecoveryEnabled && (
          <PlainButtonOrLink
            className={s['help-link']}
            href={CONTACT_SUPPORT_LINK}
          >
            {t('tfa.content.contact_support')}
          </PlainButtonOrLink>
        )}
        {!isRecoveryEnabled && (
          <PlainButtonOrLink
            className={s['help-link']}
            onClick={() => setRecoveryEnabled(true)}
          >
            {t(`tfa.content.use_recovery_code`)}
          </PlainButtonOrLink>
        )}
      </SisuPageContent>
    </SisuPage>
  );
};

export default TFAVerificationPage;
