import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Props } from './LoginForm';
import { AppState } from '../../../types/store';
import {
  changeUsername,
  changePassword,
  changeRemember,
  rememberClicked,
  submitClicked,
  forgotClicked,
  errorDisplayed,
} from '../../../actions/login';
import LoginState from '../../../types/login';

type StateProps = Pick<
  Props,
  keyof LoginState | 'recaptchaSiteKey' | 'luToken' | 'luToken2'
>;
type DispatchProps = Pick<
  Props,
  | 'changeUsername'
  | 'changePassword'
  | 'changeRemember'
  | 'submitClicked'
  | 'rememberClicked'
  | 'forgotClicked'
  | 'errorDisplayed'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  ...state.login,
  recaptchaSiteKey: state.recaptchaSiteKey,
  luToken: state.luToken,
  luToken2: state.luToken2,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  changeUsername,
  changePassword,
  changeRemember,
  rememberClicked,
  submitClicked,
  forgotClicked,
  errorDisplayed,
};

export default connect(mapStateToProps, mapDispatchToProps);
