import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import Flex from '@wix/da-ds/pkg/Flex';

import s from './SocialLoginDivider.scss';

export interface Props {
  className?: string;
}

const SocialLoginDivider: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Flex alignItems="center" className={c(s['root'], className)}>
      <div className={s['line']} />
      <div className={s['text']}>{t('login.form.social.separator.text')}</div>
      <div className={s['line']} />
    </Flex>
  );
};
SocialLoginDivider.displayName = 'SocialLoginDivider';

export default SocialLoginDivider;
