import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import SisuPage, {
  SisuPageHeader,
  SisuPageContent,
  SisuPageFooter,
} from '../SisuPage';

import s from './TFAReminderPage.scss';

export interface Props {
  referer?: string;
}

const TFAReminderPage: React.FC<Props> = ({ referer = '' }) => {
  const { t } = useTranslation();

  const learnMoreHref = t('tfaReminder.content.learnMore.linkUrl');
  const destinationHref = getRefererWithDefault(referer);
  const settingsHref = Url.buildFromPath('/settings/auth');
  return (
    <SisuPage
      className={s['root']}
      bodyClassName={s['body']}
      title={t('tfaReminder.page.title')}
      origin="/users/login"
      // if default/true, the footer buttons will be mis-styled
      useDefaultLinkStyling={false}
    >
      <SisuPageHeader className={s['header']}>
        {t('tfaReminder.content.title')}
      </SisuPageHeader>

      <SisuPageContent className={s['content']}>
        <p>{t('tfaReminder.content.summary')}</p>
        <p>{t('tfaReminder.content.explainer')}</p>
        <p>
          {t('tfaReminder.content.learnMore', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            learnMoreLink: (
              <a
                className={s['learn-more-link']}
                href={learnMoreHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('tfaReminder.content.learnMore.linkText')}
              </a>
            ),
          })}
        </p>
      </SisuPageContent>

      <SisuPageFooter className={s['footer']} useDefaultLinkStyling={false}>
        <TextButton
          className={s['footer-button']}
          href={destinationHref}
          size="large"
          variant="main"
        >
          {t('tfaReminder.footer.cancel')}
        </TextButton>
        <BrandedButton
          className={s['footer-button']}
          href={settingsHref}
          size="large"
          variant="brand-green"
        >
          {t('tfaReminder.footer.enable')}
        </BrandedButton>
      </SisuPageFooter>
    </SisuPage>
  );
};
TFAReminderPage.displayName = 'TFAReminderPage';
export default TFAReminderPage;

// TODO: combine with server/routes/post/signin?
/** returns the given referer, defaulting to site root to prevent redirect loops */
function getRefererWithDefault(
  referer = '',
  { defaultReferer = Url.buildFromPath() } = {}
) {
  return /(\/users\/login|_sisu\/do)/i.test(referer) ? defaultReferer : referer;
}
