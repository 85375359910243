import React from 'react';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';
import c from 'classnames';
import { URLS } from '@wix/da-url';
import { Link } from 'react-router-dom';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
  SisuPageFooter,
} from '../SisuPage';
import LoginForm from '../../SignupSignin/LoginForm';
import PageErrorMessage from '../../SignupSignin/PageErrorMessage';
import { includePxJs } from '../../../helpers/px';

import s from './LoginPage.scss';

export interface Props {
  urlParams: string;
  isChallenge?: boolean;
  generalError?: string;
  socialLoginError?: string;
  daProtect?: boolean;
  screenLoaded(isChallenge?: boolean): void;
  termsClicked(isChallenge?: boolean): void;
  policyClicked(isChallenge?: boolean): void;
  joinClicked?(): void;
  isSignin?: boolean;
}

class LoginPage extends React.Component<Props & WithTranslation> {
  componentDidMount() {
    this.props.screenLoaded(this.props.isChallenge);
    includePxJs();

    // Remove errors so they don't replay on refresh
    const params = new URLSearchParams(window.location.search);
    if (params.has('st_err')) {
      params.delete('st_err');
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`
      );
    }
  }

  render() {
    const {
      t,
      urlParams,
      generalError,
      socialLoginError,
      daProtect,
      joinClicked,
      isSignin,
    } = this.props;
    const joinUrl = `/join${urlParams}`;
    return (
      <SisuPage
        title={
          daProtect ? t('login.page.title.protect') : t('login.page.title')
        }
        origin="/users/login"
        errorTextKey="login"
        errorType={generalError}
        className={s['updated-ui']}
        withBackButton={isSignin}
      >
        <SisuPageHeader className={s['header']}>{t('login')}</SisuPageHeader>
        {socialLoginError && (
          <PageErrorMessage className={s['error']}>
            {t([
              `join.error.social.${socialLoginError}`,
              'join.error.social.general',
            ])}
          </PageErrorMessage>
        )}
        <div className={s['content-wrapper']}>
          <SisuPageContent
            className={c(
              s['content'],
              socialLoginError && s['content-with-error']
            )}
          >
            <LoginForm type={isSignin ? 'password' : 'username'} />
          </SisuPageContent>
          <SisuPageSubHeader className={s['sub-header']}>
            {t('updated_ui.login.become')}{' '}
            <Link className={s['sub-link']} to={joinUrl} onClick={joinClicked}>
              {t('login.signupUpdated')}
            </Link>
          </SisuPageSubHeader>
        </div>
        <SisuPageFooter className={s['footer']}>
          {this.renderTermsAndConditions()}
        </SisuPageFooter>
      </SisuPage>
    );
  }

  renderTermsAndConditions() {
    const { termsClicked, isChallenge, policyClicked } = this.props;

    // prettier-ignore
    return (
      <Trans i18nKey="login.terms">
        By clicking Log In, I confirm that I have read and agree to the DeviantArt <a
          href={URLS.TOS}
          onClick={() => termsClicked(isChallenge)}
        >
          Terms and Conditions
        </a> and <a
          href={URLS.PRIVACY}
          onClick={() => policyClicked(isChallenge)}
        >
          Privacy Policy
        </a>
      </Trans>
    );
  }
}
export default withTranslation()(LoginPage);
