import React from 'react';
import classnames from 'classnames';
import s from './SisuPage.scss';

export interface Props {
  className?: string;
  useDefaultLinkStyling?: boolean;
  children: React.ReactNode;
}

const SisuPageFooter: React.FC<Props> = ({
  className,
  useDefaultLinkStyling = true,
  children,
}) => {
  return (
    <div
      className={classnames(
        s['footer'],
        useDefaultLinkStyling && s['footer-default-link-styling'],
        className
      )}
    >
      {children}
    </div>
  );
};
SisuPageFooter.displayName = 'SisuPageFooter';

export default SisuPageFooter;
