import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import {
  BiData,
  SignupScreenClickContinueBiEvent,
} from '@wix/da-bi/pkg/events';
import { joinWithEmail, setJoinMode } from '../actions/join';
import { getJoinState } from '../selectors/join';
import JoinState from '../types/join';

function* handleJoinWithEmail(action: ReturnType<typeof joinWithEmail>) {
  // TODO validate email/password with backend?
  const joinState: JoinState = yield select(getJoinState);
  if (joinState.emailError || joinState.passwordError) {
    return;
  }
  yield put(setJoinMode('email'));
  yield call(
    logBiEvent,
    BiData<SignupScreenClickContinueBiEvent>({
      evid: 238,
      page_number: 1,
    })
  );
}

export default function* joinSaga() {
  yield all([takeLatest(joinWithEmail, handleJoinWithEmail)]);
}
