export const LOGIN_CHANGE_USERNAME = 'LOGIN_CHANGE_USERNAME';
export const LOGIN_CHANGE_PASSWORD = 'LOGIN_CHANGE_PASSWORD';
export const LOGIN_CHANGE_REMEMBER = 'LOGIN_CHANGE_REMEMBER';

export function changeUsername(username) {
  return { type: LOGIN_CHANGE_USERNAME, username };
}

export function changePassword(password) {
  return { type: LOGIN_CHANGE_PASSWORD, password };
}

export function changeRemember(remember) {
  return { type: LOGIN_CHANGE_REMEMBER, remember };
}

export * from './bi/login.bi';
