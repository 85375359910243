import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import ArrowLeftIcon from '@wix/da-ds/pkg/Icons/24x24/ArrowLeft';
import CloseButton from '@wix/da-shared-react/pkg/Button/CloseButton';
import SisuPageHead from './_partials/SisuPageHead';
import SisuPageLogo from './_partials/SisuPageLogo';
import SisuPageHero from './_partials/SisuPageHero';
import SisuPageError from './_partials/SisuPageError';
import { SisuPageProps } from './types';
import s from './SisuPage.scss';

const DesktopSisuPage: React.FC<SisuPageProps> = ({
  baseDaUrl,
  title,
  errorType,
  errorTextKey,
  rootPath,
  origin,
  refreshApp,
  cardClassName,
  cardImageUrl,
  cardDeviation,
  heroTextKey,
  className,
  isOauth,
  daProtect,
  daDreamup,
  isUnskippable,
  bodyClassName,
  logoClassName,
  heroClassName,
  children,
  cancelUrl,
  withHero = true,
  withLogo = true,
  appRefreshIsHardRefresh,
  appRefreshIsLoginRedirect,
  useDefaultLinkStyling = true,
  withBackButton,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(s['desktop-page'], className)}>
      <SisuPageHead title={title} />
      {withLogo && (
        <SisuPageLogo
          withLink={!isOauth}
          baseDaUrl={baseDaUrl}
          className={classnames(s['outer-logo'], logoClassName)}
        />
      )}
      <div
        className={classnames(
          s['card'],
          withHero && s['with-hero'],
          errorType && s['error'],
          cardClassName
        )}
      >
        {withHero && (
          <SisuPageHero
            cardImageUrl={cardImageUrl}
            cardDeviation={cardDeviation}
            baseDaUrl={baseDaUrl}
            heroTextKey={
              daProtect ? 'protect' : daDreamup ? 'dreamup' : heroTextKey
            }
            withLogo={withLogo}
            withLogoLink={!isOauth}
            className={heroClassName}
            daProtect={daProtect}
            daDreamup={daDreamup}
          />
        )}
        <div className={s['card-content']}>
          {withBackButton && (
            <TextButton
              className={s['back-button']}
              variant="main"
              size="small"
              icon={ArrowLeftIcon}
              onClick={() =>
                // cant go back to a page that was shown due to login POST request for password, so go back to username page
                document.referrer.includes('step2')
                  ? // eslint-disable-next-line no-restricted-globals
                    history.go(-2)
                  : // eslint-disable-next-line no-restricted-globals
                    history.back()
              }
            >
              {t('login.back')}
            </TextButton>
          )}
          {!isOauth && !isUnskippable && (
            <CloseButton
              className={s['close-button']}
              view={'b5-1'}
              href={cancelUrl}
            />
          )}
          <div
            className={classnames(
              s['body'],
              useDefaultLinkStyling && s['body-default-link-styling'],
              withBackButton && s['body-with-back-button'],
              bodyClassName
            )}
          >
            {children}
          </div>
          {errorType && (
            <SisuPageError
              errorType={errorType}
              errorTextKey={errorTextKey}
              rootPath={rootPath}
              origin={origin}
              refreshApp={refreshApp}
              hardRefresh={appRefreshIsHardRefresh}
              loginRefresh={appRefreshIsLoginRedirect}
            />
          )}
        </div>
      </div>
    </div>
  );
};
DesktopSisuPage.displayName = 'DesktopSisuPage';

export default DesktopSisuPage;
