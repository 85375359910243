import React from 'react';
import classnames from 'classnames';
import CloseButton from '@wix/da-shared-react/pkg/Button/CloseButton';
import DaProtectLogo from '@wix/da-ds/pkg/DaProtectLogo';
import DaDreamupLogoAndText from '@wix/da-ds/pkg/DaDreamupLogoAndText';
import SisuPageHead from './_partials/SisuPageHead';
import SisuPageLogo from './_partials/SisuPageLogo';
import SisuPageError from './_partials/SisuPageError';
import { SisuPageProps } from './types';
import s from './SisuPage.scss';

const MobileSisuPage: React.FC<SisuPageProps> = ({
  title,
  withLogo = true,
  withHero = true,
  baseDaUrl,
  isOauth,
  isUnskippable,
  errorType,
  errorTextKey,
  rootPath,
  origin,
  refreshApp,
  className,
  bodyClassName,
  cardClassName,
  logoClassName,
  children,
  cancelUrl,
  daProtect,
  daDreamup,
  appRefreshIsHardRefresh,
  appRefreshIsLoginRedirect,
  useDefaultLinkStyling = true,
}) => {
  return (
    <div
      className={classnames(
        s['mobile-page'],
        s['updated-ui'],
        className,
        errorType && s['error'],
        useDefaultLinkStyling && s['default-link-styling']
      )}
    >
      <SisuPageHead title={title} />
      {withLogo &&
        (daProtect ? (
          <DaProtectLogo highlighted className={s['protect-logo-mobile']} />
        ) : daDreamup ? (
          <DaDreamupLogoAndText className={s['dreamup-logo-mobile']} />
        ) : (
          <SisuPageLogo
            withLink={!isOauth}
            baseDaUrl={baseDaUrl}
            className={classnames(s['logo'], logoClassName)}
          />
        ))}
      {!isOauth && !isUnskippable && (
        <CloseButton
          className={s['close-button']}
          view={'b5-1'}
          onClick={() => window.location.assign(cancelUrl)}
        />
      )}
      <div
        className={classnames(
          s['body'],
          useDefaultLinkStyling && s['body-default-link-styling'],
          bodyClassName,
          cardClassName
        )}
      >
        {children}
      </div>
      {errorType && (
        <SisuPageError
          errorType={errorType}
          errorTextKey={errorTextKey}
          rootPath={rootPath}
          origin={origin}
          refreshApp={refreshApp}
          hardRefresh={appRefreshIsHardRefresh}
          loginRefresh={appRefreshIsLoginRedirect}
        />
      )}
    </div>
  );
};
MobileSisuPage.displayName = 'MobileSisuPage';

export default MobileSisuPage;
