import React, { useState } from 'react';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';

export interface Props {}

const setCookie = (key: string, expireInSeconds: number = 86400) => {
  const expires = new Date(Date.now() + expireInSeconds * 1000);
  document.cookie = `${key}=1; path=/; samesite=lax; expires=${expires.toUTCString()}`;
};

const hasCookie = (key: string) =>
  typeof document !== 'undefined' &&
  document.cookie.split('; ').some(e => e.startsWith(`${key}=`));

const unsetCookie = (key: string) => {
  setCookie(key, -1);
};

const COOKIE_KEYS = ['pwa'];

const getCookieState = () =>
  COOKIE_KEYS.reduce(
    (acc, key) => ({ ...acc, [key]: hasCookie(key) }),
    {} as { [key: string]: boolean }
  );

const ManageCookiesPage: React.FC<Props> = () => {
  const [isCookieSet, setIsCookieSet] = useState(getCookieState);
  const isMounted = useIsMounted();
  if (!isMounted) {
    return null;
  }

  return (
    <div style={{ padding: 20 }}>
      {COOKIE_KEYS.map(key => {
        const color = isCookieSet[key] ? '#0d0' : '#d00';
        const text = isCookieSet[key] ? 'SET' : 'NOT SET';

        const onSetCookie = () => {
          setCookie(key);
          setIsCookieSet(cookies => ({
            ...cookies,
            [key]: hasCookie(key),
          }));
        };

        const onUnsetCookie = () => {
          unsetCookie(key);
          setIsCookieSet(cookies => ({
            ...cookies,
            [key]: hasCookie(key),
          }));
        };

        return (
          <div key={key} style={{ margin: '16px 0' }}>
            <div>{key}</div>
            <div>
              Cookie is <span style={{ color }}>{text}</span>. (The cookie
              expires in 1 day.)
            </div>
            <br />
            <div>
              <button onClick={onSetCookie}>Set cookie</button>
              <button onClick={onUnsetCookie}>Unset cookie</button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
ManageCookiesPage.displayName = 'ManageCookiesPage';

export default ManageCookiesPage;
