import { all, takeEvery, call } from 'redux-saga/effects';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';

import { BI_SISU_EVENT } from '../actions/bi/bi';

function* biClick(action) {
  yield call(logBiEvent, action.payload);
}

export default function* biLogger() {
  yield all([takeEvery(BI_SISU_EVENT, biClick)]);
}
