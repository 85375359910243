import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import BooleanInput from '@wix/da-ds/pkg/formControls/dsPresets/BooleanInput';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks/useFlag';
import LoginState from '../../../types/login';
import Recaptcha from '../Recaptcha';
import SisuForm from '../SisuForm';
import PasswordInput from '../PasswordInput';
import SocialButtonApple from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonApple';
import SocialButtonFacebook from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonFacebook';
import SocialButtonGoogle from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonGoogle';
import SocialButtonTest from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonTest';
import SocialLoginDivider from '../SocialLoginDivider';
import CompleteRedirectForm from '../CompleteRedirectForm';

import s from './LoginForm.scss';

export interface Props extends LoginState {
  recaptchaSiteKey?: string;
  changeUsername(username: string): void;
  changePassword(password: string): void;
  changeRemember(remember: boolean): void;
  submitClicked(isChallenge?: boolean): void;
  rememberClicked(newState: boolean, isChallenge?: boolean): void;
  forgotClicked(isChallenge?: boolean): void;
  errorDisplayed(
    errorType: string,
    errorMessage: string,
    isChallenge?: boolean
  ): void;
  type: 'username' | 'password';
  luToken?: string;
  luToken2?: string;
}

const LoginForm: React.FC<Props> = ({
  errorType,
  generalError,
  usernameInvalid,
  isChallenge,
  username,
  password,
  passwordInvalid,
  remember,
  recaptchaSiteKey,
  showCaptcha,
  changeUsername,
  changePassword,
  rememberClicked,
  changeRemember,
  errorDisplayed,
  forgotClicked,
  submitClicked,
  type,
  luToken,
  luToken2,
}) => {
  const { t } = useTranslation();
  const form = useRef<HTMLFormElement | null>(null);
  const recaptcha = useRef<Recaptcha | null>(null);
  const reactor = useFlag('reactor');

  const usernameError = usernameInvalid && t('login.error.usernotfound');
  const usernameLookLikeEmail = username?.includes('@') ?? false;
  const usernameHasErrors =
    !!usernameError || (isChallenge && usernameLookLikeEmail);
  const usernameHint =
    isChallenge && usernameLookLikeEmail && t('login.error.usernameonly');
  const passwordError = passwordInvalid && t('login.error.wrongpassword');
  const generalErrorMsg =
    generalError &&
    t([
      `login.error.general.${generalError}.detail`,
      `error.general.${generalError}.detail`,
      'error.general.detail',
    ]);

  useEffect(() => {
    // There are a few assumptions here that may change in the future.
    // 1. We are only displaying errors after a submit so no need to check on updates
    // 2. We only support one error at a time
    // 3. this.props.errorType is always set if there is any error
    if (!errorType) {
      return;
    }
    const errorMessage = usernameError || passwordError || generalErrorMsg;

    errorDisplayed(errorType, errorMessage || '', isChallenge);
  }, [
    errorType,
    isChallenge,
    usernameError,
    passwordError,
    generalErrorMsg,
    errorDisplayed,
  ]);

  const onSubmitClick = e => {
    submitClicked(isChallenge);

    if (showCaptcha) {
      e.preventDefault();
      recaptcha.current?.execute();
    }
  };

  const joinSections = [
    <React.Fragment key="form">
      <input type="hidden" name="challenge" value={isChallenge ? '1' : '0'} />
      <input type="hidden" name="lu_token" value={luToken} />
      {luToken2 && <input type="hidden" name="lu_token2" value={luToken2} />}
      {type === 'password' && (
        <input type="hidden" name="username" value={username} />
      )}
      {type === 'username' && (
        <TextInput
          id="username"
          name="username"
          label={
            isChallenge
              ? t('login.form.usernameonly')
              : t('login.form.username')
          }
          value={username || ''}
          validationResult={usernameHasErrors ? 'error' : undefined}
          details={
            usernameHasErrors ? (
              <span className={s['field-error-msg']}>{usernameError}</span>
            ) : (
              usernameHint
            )
          }
          onChange={ev => changeUsername(ev.target.value)}
          className={s['input']}
          autoFocus
        />
      )}
      {type === 'password' && (
        <PasswordInput
          id="password"
          name="password"
          label={t('login.form.password')}
          value={password || ''}
          validationResult={passwordInvalid ? 'error' : undefined}
          details={
            passwordError && (
              <span className={s['field-error-msg']}>{passwordError}</span>
            )
          }
          onChange={ev => changePassword(ev.target.value)}
          className={s['input']}
          autoFocus
        />
      )}
      <div className={s['pre-actions']}>
        <BooleanInput
          className={s['checkbox']}
          labelClassName={s['checkbox-label']}
          name="remember"
          id="remember"
          label={t('login.form.rememberme')}
          value={remember}
          size="large"
          onChange={value => {
            rememberClicked(value, isChallenge);
            changeRemember(value);
          }}
          noBottomSpacing
        />
        <Link
          onClick={() => {
            forgotClicked(isChallenge);
          }}
          className={s['forgot']}
          to="/users/forgot?kind=password"
        >
          {t('updated_ui.login.forgot')}
        </Link>
      </div>
      {showCaptcha ? (
        <Recaptcha
          invisible
          siteKey={recaptchaSiteKey!}
          onRecaptchaSubmit={() => form.current?.submit()}
          ref={r => (recaptcha.current = r) as any}
        />
      ) : null}
      <div className={s['form-actions']}>
        <BrandedButton
          aria-label={t('login.form.submit')}
          id="loginbutton"
          onClick={onSubmitClick}
          size="large"
          variant="brand-green"
          type="submit"
          fillContainerWidth
          className={s['submit-button']}
        >
          {t(
            type === 'password'
              ? 'login.form.submit'
              : 'login.form.submit.continue'
          )}
        </BrandedButton>
      </div>
    </React.Fragment>,
    <React.Fragment key="social">
      {type === 'username' && (
        <>
          <SocialLoginDivider key="divider" className={s['divider']} />
          <div className={s['social-buttons']}>
            <SocialButtonGoogle
              className={classnames(s['social-button'])}
              redirectToJoin
            />

            <SocialButtonApple className={s['social-button']} redirectToJoin>
              {t('join.form.button.continue.apple')}
            </SocialButtonApple>

            <SocialButtonFacebook className={s['social-button']} redirectToJoin>
              {t('join.form.button.continue.facebook')}
            </SocialButtonFacebook>

            {reactor && (
              <SocialButtonTest className={s['social-button']} redirectToJoin />
            )}
          </div>
        </>
      )}
    </React.Fragment>,
  ];

  const action = type === 'password' ? 'signin' : 'step2';

  return (
    <>
      <SisuForm
        action={`/_sisu/do/${action}`}
        method="post"
        className={classnames(s['login-form'], s['updated-ui'])}
        formRef={ref => (form.current = ref)}
      >
        {joinSections}
      </SisuForm>
      <CompleteRedirectForm />
    </>
  );
};
LoginForm.displayName = 'LoginForm';

export default LoginForm;
