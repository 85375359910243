import { connect, MapStateToProps } from 'react-redux';
import { isUserOlderThan18 } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { AppState } from '../../../types/store';
import { Props } from './ContentFilterPage';

type StateProps = Pick<Props, 'isUserOlderThan18'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isUserOlderThan18: isUserOlderThan18(state),
});

export default connect(mapStateToProps, null);
