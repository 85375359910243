import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import c from 'classnames';
import Button from '@wix/da-shared-react/pkg/Button';
import isValidEmailAddress from '@wix/da-shared-react/pkg/utils/isValidEmailAddress';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import { Link } from 'react-router-dom';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
} from '../SisuPage';
import SisuForm from '../../SignupSignin/SisuForm';
import { includePxJs } from '../../../helpers/px';
import styles from './ForgotPage.scss';

export interface ForgotPageProps {
  kind: 'password' | 'username';
  username?: string;
  usernameError?: string;
  emailSent?: boolean;
  generalError?: string;
  daProtect?: boolean;
  changeUsername(username: string): void;
}

const ForgotPage: React.FC<ForgotPageProps> = ({
  kind,
  username,
  usernameError,
  emailSent,
  generalError,
  daProtect,
  changeUsername,
}) => {
  const { t } = useTranslation();
  const form = useRef<HTMLFormElement | null>(null);
  const [hasEmailError, setEmailError] = useState(false);
  const [emailErrorDetails, setEmailErrorDetails] = useState('');

  useEffect(() => {
    includePxJs();
  }, []);

  const oppositeKind = kind === 'password' ? 'username' : 'password';
  let usernameErrorDetails;
  if (usernameError === 'social_account') {
    usernameErrorDetails = (
      <>
        <div>{t(`forgot.error.social_account.${kind}`)}</div>
        <div>
          <a href="/users/login" className={styles['login-link']}>
            {t('forgot.link.login')}
          </a>
        </div>
      </>
    );
  } else if (usernameError) {
    usernameErrorDetails = t(`forgot.error.${usernameError}`);
  }

  const handleSubmit = e => {
    if (kind === 'username' && !isValidEmailAddress(username)) {
      e.preventDefault();
      setEmailError(true);
      setEmailErrorDetails(t(`forgot.error.invalid_email_address`));
      return;
    }
    if (kind === 'password' && isValidEmailAddress(username)) {
      e.preventDefault();
      setEmailError(true);
      setEmailErrorDetails(t(`forgot.error.invalid_username`));
      return;
    }
    setEmailError(false);
    form.current?.submit();
  };

  return (
    <SisuPage
      className={c(styles['page'], daProtect && styles['protect'])}
      title={t(`forgot.page.${kind}.title`)}
      origin="/users/login"
      errorTextKey="login"
      errorType={generalError}
      withBackButton
    >
      {emailSent ? (
        <div className={styles['forgot-form-sent']}>
          <SisuPageHeader className={styles['header']}>
            {t('forgot.sent.title')}
          </SisuPageHeader>
          <SisuPageSubHeader className={styles['subheader']}>
            {t('forgot.sent.instruction')}
          </SisuPageSubHeader>
          <SisuPageContent className={styles['content']}>
            <Link to="/users/login" className={styles['sent-back']}>
              <Button className={styles['button']} type="submit">
                {t('forgot.sent.back')}
              </Button>
            </Link>
          </SisuPageContent>
        </div>
      ) : (
        <>
          <SisuPageHeader className={styles['header']}>
            {t(`forgot.${kind}.title`)}
          </SisuPageHeader>
          <SisuPageSubHeader className={styles['subheader']}>
            <Trans i18nKey={`forgot.${kind}.instruction`}>
              <br />
            </Trans>
          </SisuPageSubHeader>
          <SisuPageContent
            className={c(styles['content'], styles['input-updated'])}
          >
            <SisuForm
              action={`/_sisu/do/forgot?kind=${kind}`}
              method="post"
              className={styles['forgot-form']}
              onSubmit={handleSubmit}
              formRef={ref => (form.current = ref)}
            >
              <TextInput
                id="username"
                name="username"
                label={t(`forgot.form.${kind}.input`)}
                value={username || ''}
                validationResult={
                  hasEmailError || usernameError ? 'error' : undefined
                }
                details={usernameErrorDetails || emailErrorDetails}
                onChange={ev => changeUsername(ev.target.value)}
                className={styles['input']}
                autoFocus
              />
              <div className={styles['forgot-form-actions']}>
                <Link
                  className={styles['forgot-link']}
                  to={`/users/forgot?kind=${oppositeKind}`}
                  onClick={() => {
                    setEmailErrorDetails('');
                    setEmailError(false);
                  }}
                >
                  {t(`forgot.link.${oppositeKind}`)}
                </Link>
                <Button
                  className={styles['button']}
                  type="button"
                  onClick={handleSubmit}
                  disabled={!username || !!usernameError}
                >
                  {t('forgot.form.submit')}
                </Button>
              </div>
            </SisuForm>
          </SisuPageContent>
        </>
      )}
    </SisuPage>
  );
};
ForgotPage.displayName = 'ForgotPage';

export default ForgotPage;
