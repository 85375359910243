import {
  all,
  put,
  call,
  fork,
  throttle,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { AppState } from '../types/store';
import axios from 'axios';
import {
  JOIN_CHANGE_USERNAME,
  changeUsernameAvailable,
  fetchUsernameAvailable as fetchUsernameAvailableAction,
} from '../actions/join';
import biLogger from './biLogger';
import topicSaga from './topic';
import joinSaga from './join';
import socialSaga from './social';
import { API_BASE_PATH } from '../../constants';
import compliantLocalStorageSaga from '@wix/da-react-context/pkg/compliantLocalStorage/redux/saga';
import { initializeCompliantLocalStorage } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/actions';
import publicSessionSaga from '@wix/da-shared-react/pkg/publicSession/saga';
import socialLoginSaga from '@wix/da-shared-react/pkg/SocialLogin/redux/saga';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';

export function fetchUsernameAvailable(username) {
  return axios
    .get(`${API_BASE_PATH}/checkusername`, { params: { username } })
    .then(response => response.data)
    .catch(response => false);
}

export function* onJoinUsernameChange() {
  const selectedState = yield select((state: AppState) => ({
    username: state.join.username,
    usernameInvalid: state.join.usernameError === 'invalid',
  }));
  if (
    !selectedState.username ||
    selectedState.username.length < 3 ||
    selectedState.usernameInvalid
  ) {
    return;
  }
  yield put(fetchUsernameAvailableAction());
  const available = yield call(fetchUsernameAvailable, selectedState.username);
  yield put(changeUsernameAvailable(available, selectedState.username));
}

export function* handleAppMounted() {
  yield put(initializeCompliantLocalStorage());
}

export default function* rootSaga() {
  yield all([
    throttle(500, JOIN_CHANGE_USERNAME, onJoinUsernameChange),
    fork(biLogger),
    fork(topicSaga),
    fork(publicSessionSaga),
    fork(compliantLocalStorageSaga),
    takeLatest(appMounted, handleAppMounted),
    fork(joinSaga),
    fork(socialSaga),
    fork(socialLoginSaga),
  ]);
}
