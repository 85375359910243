// DS6.21 Bold Selection Card
import React from 'react';
import c from 'classnames';
import PlainButtonOrLink, {
  Props as PlainButtonOrLinkProps,
} from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

import s from './SelectionCard.scss';

type ButtonPropsUsed = Omit<PlainButtonOrLinkProps, 'loading' | 'title'>;
export interface Props extends ButtonPropsUsed {
  className?: string;
  illustrationSrc?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  isSelected?: boolean;
}

const SelectionCard: React.FC<Props> = ({
  className,
  illustrationSrc,
  title,
  subtitle,
  isSelected,
  ...buttonProps
}) => {
  return (
    <PlainButtonOrLink
      {...buttonProps}
      className={c(
        className,
        isSelected && 'ds-card-selected-thick',
        s['root'],
        'ds-card'
      )}
    >
      <div className={s['indicator']} />
      {illustrationSrc && (
        <img className={s['illustration']} src={illustrationSrc} alt="_" />
      )}
      <div className={s['title']}>{title}</div>
      <div className={s['subtitle']}>{subtitle}</div>
    </PlainButtonOrLink>
  );
};
SelectionCard.displayName = 'SelectionCard';

export default SelectionCard;
