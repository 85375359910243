import { all, takeLatest, put, select } from 'redux-saga/effects';
import { Url } from '@wix/da-url';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { socialLoginSuccess } from '@wix/da-shared-react/pkg/SocialLogin/redux/actions';
import { getCompliantLocalStorageItem } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/selectors';
import { removeCompliantLocalStorageItem } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/actions';
import { CompliantLocalStorageKeys } from '@wix/da-react-context/pkg/compliantLocalStorage/redux/constants';
import { COMPLETE_REDIRECT_FORM_ID } from '../../constants';
import { setJoinMode } from '../actions/join';
import { setSocialLoginTokenId } from '../actions/social';
import { getReferer } from '../selectors/page';
import { isRefererOauth } from '../selectors/social';
import history from '../history';
import logger from '../helpers/logger';

function* handleLoginSuccess(action: ReturnType<typeof socialLoginSuccess>) {
  const { provider, response, redirectToJoin } = action.payload;
  logger.info('Social login success ', action.payload.response);

  if (response.loggedin) {
    const referer = yield select(getReferer);

    // Our Android app uses Chrome Mobile to authenticate users.
    // It uses `oauth://deviantart` as the eventual redirect URI.
    // We can't simply do a JS redirect because Chrome blocks such redirects.
    // Instead we do a form submit to a url, that in turn issues another redirect
    // to where we actually need to go.
    // See T56021
    const isOauth = yield select(isRefererOauth);
    if (isOauth) {
      const form = document.getElementById(
        COMPLETE_REDIRECT_FORM_ID
      ) as HTMLFormElement | null;
      if (form) {
        form.submit();
        return;
      }
    }

    window.location.assign(referer ?? Url.browseHomeLink());
    return;
  }

  // Go to signup flow, if we are not logged in
  yield put(setSocialLoginTokenId(provider, response.tokenId ?? ''));
  yield put(setJoinMode('social'));
  if (redirectToJoin) {
    history.push('/join');
  }
}

function* handleAppMounted(action: ReturnType<typeof appMounted>) {
  const tokenAndProvider = yield select(
    getCompliantLocalStorageItem,
    CompliantLocalStorageKeys.SOCIAL
  );
  if (!tokenAndProvider) {
    return;
  }
  logger.info('Got token/provider from local storage: ', tokenAndProvider);
  yield put(removeCompliantLocalStorageItem(CompliantLocalStorageKeys.SOCIAL));
  const [provider, tokenId] = tokenAndProvider.split(':');
  if (!provider || !tokenId) {
    return;
  }
  yield put(setSocialLoginTokenId(provider, tokenId));
  yield put(setJoinMode('social'));
}

export default function* socialSaga() {
  yield all([
    takeLatest(socialLoginSuccess, handleLoginSuccess),
    takeLatest(appMounted, handleAppMounted),
  ]);
}
