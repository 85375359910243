import React from 'react';
import classnames from 'classnames';
import s from './SisuPage.scss';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const SisuPageSubHeader: React.FC<Props> = ({ className, children }) => {
  return <p className={classnames(s['sub-header'], className)}>{children}</p>;
};
SisuPageSubHeader.displayName = 'SisuPageSubHeader';

export default SisuPageSubHeader;
