export function includePxJs() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!document.getElementById('pxjs')) {
    const p = document.getElementsByTagName('script')[0],
      s = document.createElement('script');
    s.async = true;
    s.id = 'pxjs';
    s.src = '//client.px-cloud.net/PXiUbOGhJL/main.min.js';
    p?.parentNode?.insertBefore(s, p);
  }
}
