import 'react-hot-loader/patch';
import React from 'react';
import initI18n from '@wix/da-i18next/pkg/presetI18nClientConfig';
// project-base needs to go before app styles
// eslint-disable-next-line
import '@wix/da-ds/src/styles/project-base.scss';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { configureStore } from './store/configure-store';
import history from './history';
import DaAppWrapper from '@wix/da-shared-react/pkg/DaApp/DaAppWrapper';
import { hydrateRoot } from 'react-dom/client';

const initialI18n = window['__INITIAL_I18N__'];
initI18n(initialI18n);

const initialState = window['__INITIAL_STATE__'];
delete window['__INITIAL_STATE__'];
const store = configureStore(initialState);

const root = hydrateRoot(
  document.getElementById('root')!,
  <DaAppWrapper store={store}>
    <Router history={history}>
      <App language={initialI18n.locale} />
    </Router>
  </DaAppWrapper>
);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line global-require
    const AppLatest = require('./components/App').default;
    root.render(
      <AppContainer>
        <DaAppWrapper store={store}>
          <Router history={history}>
            <AppLatest />
          </Router>
        </DaAppWrapper>
      </AppContainer>
    );
  });
}
