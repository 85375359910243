import React, { useEffect } from 'react';

interface Props {}

/** hackity hack to close muro's login modal... */
const MuroModalClosePage: React.FC<Props> = () => {
  useEffect(() => {
    try {
      (window as any).parent.Modals.pop({
        _source: 'signup',
        result: 'finished',
        pageData: { 'deviantART.user_agreed_to_submission_policy': true },
      });
    } catch {
      // probably some weird cross frame bs again... so let the user do it themselves
    }
  }, []);

  return (
    <div>
      <h2>All Done!</h2>
      Click the X to go back to your page.
    </div>
  );
};
MuroModalClosePage.displayName = 'MuroModalClosePage';

export default MuroModalClosePage;
