import { connect, MapStateToProps } from 'react-redux';
import { AppAuthorizationPageProps } from './AppAuthorizationPage';
import { AppState } from '../../../types/store';

type StateProps = Pick<AppAuthorizationPageProps, 'appDeviation'>;
type OwnProps = Omit<AppAuthorizationPageProps, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  appDeviation: state.oauth.appSettings?.deviation,
  requestedScope: state.oauth.appSettings?.requestedScope,
});

export default connect(mapStateToProps);
