import React, { useContext, useEffect } from 'react';
// import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  ThemeOptions,
  ThemeOverride,
} from '@wix/da-react-context/pkg/ThemeContext';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import Logo from '@wix/da-ds/pkg/Logo';
import SisuForm from '../../SignupSignin/SisuForm';

import s from './OnboardingPage.scss';

const preventDefault = ev => ev.preventDefault();

export interface Props {
  children?: React.ReactNode;
  pageTitle?: string;
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  authMode: string;
  baseDaUrl: string;
  isOauth?: boolean;
  /** where does the SisuForm post the data? */
  postFormTo?: string;
  /** is the continue button disabled?  */
  canContinue?: boolean;
  /** if given, fired after clickContinue(authMode) is called */
  onContinue?: () => void;
  screenLoaded: (authMode: string) => void;
  clickContinue: (authMode: string) => void;
}

/** dark themed full height page for intents, content-filter, and topics */
export const OnboardingPage: React.FC<Props> = ({
  pageTitle,
  heading,
  subheading,
  authMode,
  baseDaUrl,
  isOauth,
  canContinue,
  postFormTo,
  children,
  onContinue = noop,
  screenLoaded,
  clickContinue,
}) => {
  const { t } = useTranslation();
  const isDesktop = !useContext(MobileContext);

  useEffect(() => {
    screenLoaded(authMode);
  }, [screenLoaded, authMode]);

  const logo = isOauth ? (
    <Logo className={s['logo']} size="small" textColor="white" />
  ) : (
    <a className={s['logo']} href={baseDaUrl} aria-label="DeviantArt">
      <Logo size="small" textColor="white" />
    </a>
  );

  return (
    // pages are forced to light in sisu (no user pref yet), but design is dark here
    <ThemeOverride className={s['root']} tag="div" theme={ThemeOptions.DARK}>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}

      {isDesktop && logo}

      <SisuForm
        className={s['form']}
        method="post"
        // if no post endpoint is given prevent default submit on the form
        {...(postFormTo
          ? { action: postFormTo }
          : { action: '', onSubmit: preventDefault })}
      >
        {heading && (
          <header className={s['header']}>
            <Heading className={s['heading']}>{heading}</Heading>
            {subheading && <p className={s['subheading']}>{subheading}</p>}
          </header>
        )}

        {children}

        <BrandedButton
          className={s['continue-button']}
          size="large"
          variant="brand-green"
          type="submit"
          disabled={!canContinue}
          onClick={() => {
            clickContinue(authMode);
            onContinue();
          }}
        >
          {t('onboardingPage.continue')}
        </BrandedButton>
      </SisuForm>
    </ThemeOverride>
  );
};

export default OnboardingPage;
