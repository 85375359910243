import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import { Props } from './SisuForm';

type StateProps = Pick<
  Props,
  | 'referer'
  | 'refererType'
  | 'rootPath'
  | 'csrfToken'
  | 'daProtect'
  | 'daDreamup'
>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    referer: state.referer,
    refererType: state.refererType,
    rootPath: state.rootPath,
    csrfToken: state.csrfToken,
    daProtect: state.params.daProtect,
    daDreamup: state.params.daDreamup,
  };
};

// Pass empty object as mapDispatchToProps because otherwise
// `connect` injects a `dispatch` prop into `SisuForm`,
// which is injected into <form> tag, leading to a spammy React warning.
export default connect(mapStateToProps, {});
