import { createAction } from 'redux-actions';
import { BI_SISU_EVENT, AuthMode } from './bi';
import {
  AlmostDoneChangeAddressClickBiEvent,
  AlmostDoneCloseBiEvent,
  AlmostDoneResendClickBiEvent,
  AlmostDoneShowBiEvent,
  BiData,
  LoginOnSignupClickBiEvent,
  PolicyClickBiEvent,
  SignupErrorBiEvent,
  SignupOnSignupClickBiEvent,
  SignupScreenShowBiEvent,
  SignupSuccessBiEvent,
  TermsClickBiEvent,
  WelcomeChangeEmailClickBiEvent,
  WelcomeScreenCloseBiEvent,
  WelcomeScreenShowBiEvent,
  WelcomeStartClickBiEvent,
} from '@wix/da-bi/pkg/events';

export const screenLoaded = createAction(BI_SISU_EVENT, () =>
  BiData<SignupScreenShowBiEvent>({
    evid: 243,
  })
);

export const loginClicked = createAction(BI_SISU_EVENT, () =>
  BiData<LoginOnSignupClickBiEvent>({
    evid: 245,
  })
);

export const termsClicked = createAction(BI_SISU_EVENT, () =>
  BiData<TermsClickBiEvent>({
    evid: 206,
    user_name_challenge_mode: 0,
  })
);

export const policyClicked = createAction(BI_SISU_EVENT, () =>
  BiData<PolicyClickBiEvent>({
    evid: 207,
    user_name_challenge_mode: 0,
  })
);

export const submitClicked = createAction(BI_SISU_EVENT, () =>
  BiData<SignupOnSignupClickBiEvent>({
    evid: 247,
  })
);

export const joinSuccess = createAction(BI_SISU_EVENT, (authMode: AuthMode) =>
  BiData<SignupSuccessBiEvent>({
    evid: 248,
    auth_mode: authMode,
  })
);

export const errorDisplayed = createAction(
  BI_SISU_EVENT,
  (errorMessage: string) =>
    BiData<SignupErrorBiEvent>({
      evid: 249,
      error_message: errorMessage,
    })
);

export const welcomePopupShown = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<WelcomeScreenShowBiEvent>({
      evid: 216,
      auth_mode: authMode,
      absent_days: null,
      is_new_user: true,
    })
);

export const welcomePopupClosed = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<WelcomeScreenCloseBiEvent>({
      evid: 217,
      auth_mode: authMode,
      is_new_user: true,
    })
);

export const welcomeClickStart = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<WelcomeStartClickBiEvent>({
      evid: 218,
      auth_mode: authMode,
      is_new_user: true,
    })
);

export const welcomeClickEmail = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<WelcomeChangeEmailClickBiEvent>({
      evid: 219,
      auth_mode: authMode,
      is_new_user: true,
    })
);

export const showVerifyNotification = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<AlmostDoneShowBiEvent>({
      evid: 228,
      auth_mode: authMode,
    })
);

export const verifyClickChange = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<AlmostDoneChangeAddressClickBiEvent>({
      evid: 230,
      auth_mode: authMode,
    })
);

export const verifyClickResend = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<AlmostDoneResendClickBiEvent>({
      evid: 229,
      auth_mode: authMode,
    })
);

export const verifyClickClose = createAction(
  BI_SISU_EVENT,
  (authMode: AuthMode) =>
    BiData<AlmostDoneCloseBiEvent>({
      evid: 231,
      auth_mode: authMode,
    })
);
