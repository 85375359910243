import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import OnboardingPage from '../../SignupSignin/OnboardingPage';
import SelectionCard from './SelectionCard';

import s from './ContentFilterPage.scss';

const CONTENT_FILTER_OPTIONS = ['safe', 'standard', 'mature'];
type contentFilterOptions = (typeof CONTENT_FILTER_OPTIONS)[number];
const UNDER_18_CONTENT_FILTER_OPTIONS = ['safe', 'standard'];
const DEFAULT_CONTENT_FILTER = 'standard';

const IMG_NAMES: Record<contentFilterOptions, string> = {
  safe: 'il4.20_safe_mode',
  standard: 'il4.21_standard_mode',
  mature: 'il4.22_mature_mode',
};

export interface Props {
  isUserOlderThan18: boolean;
}

export const ContentFilterPage: React.FC<Props> = ({ isUserOlderThan18 }) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  const [selectedFilter, selectFilter] = useState<contentFilterOptions>(
    DEFAULT_CONTENT_FILTER
  );
  const filterOptions = isUserOlderThan18
    ? CONTENT_FILTER_OPTIONS
    : UNDER_18_CONTENT_FILTER_OPTIONS;

  const imgSize = isMobile ? '80px' : '120px';
  return (
    <OnboardingPage
      pageTitle={t('onboarding.contentFilter.page.title')}
      heading={t('onboarding.contentFilter.heading')}
      subheading={t('onboarding.contentFilter.subheading')}
      canContinue={!!selectedFilter}
      postFormTo="/_sisu/do/save_content_filter"
    >
      <div
        className={s['filters']}
        role="radiogroup"
        aria-label={t('onboarding.contentFilter.filterSelection.a11y.label')}
      >
        {filterOptions.map(value => {
          const imgCDNPath = Url.staticCDNLink(
            `eclipse/sisu/${IMG_NAMES[value]}_${imgSize}.png`
          );
          const localePrefix = 'onboarding.contentFilter.filterSelection';
          const isSelected = value === selectedFilter;
          return (
            <SelectionCard
              className={s['filter-selector']}
              key={value}
              illustrationSrc={imgCDNPath}
              title={t(`${localePrefix}.title.${value}`)}
              subtitle={t(`${localePrefix}.subtitle.${value}`)}
              type="button"
              role="radio"
              isSelected={isSelected}
              aria-checked={isSelected}
              onClick={() => selectFilter(value)}
            />
          );
        })}

        <input type="hidden" name="content_filter" value={selectedFilter} />
      </div>
    </OnboardingPage>
  );
};
export default ContentFilterPage;
