import React from 'react';
import { COMPLETE_REDIRECT_FORM_ID } from '../../../../constants';

export interface Props {
  referer?: string;
}

/**
 * Used to complete the redirect when you sign in with a social login account.
 * See sagas/social.ts
 */
const CompleteRedirectForm: React.FC<Props> = ({ referer }) => (
  <form
    id={COMPLETE_REDIRECT_FORM_ID}
    method="post"
    action="/_sisu/do/complete_redirect"
  >
    <input type="hidden" name="referer" value={referer} />
  </form>
);

CompleteRedirectForm.displayName = 'CompleteRedirectForm';

export default CompleteRedirectForm;
