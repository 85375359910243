import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import { toggleIntent } from '../../../actions/bi/intent.bi';

import { Props } from './IntentsPage';

type StateProps = Pick<Props, 'authMode'>;
type DispatchProps = Pick<Props, 'toggleIntent'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  authMode: state.authMode,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  toggleIntent,
};

export default connect(mapStateToProps, mapDispatchToProps);
