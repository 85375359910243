import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Logo from '@wix/da-ds/pkg/Logo';

export interface Props {
  baseDaUrl: string;
  withLink?: boolean;
  className?: string;
}

const SisuPageLogo: React.FC<Props> = ({
  baseDaUrl,
  className,
  withLink = true,
}) => {
  const isMobile = useContext(MobileContext);

  const logo = (
    <Logo
      className={className}
      size={isMobile ? 'tiny' : 'small'}
      textColor={isMobile ? 'black' : 'white'}
    />
  );
  if (withLink) {
    return (
      <a href={baseDaUrl} title="DeviantArt" aria-label="DeviantArt">
        {logo}
      </a>
    );
  }
  return logo;
};
SisuPageLogo.displayName = 'SisuPageLogo';

export default SisuPageLogo;
