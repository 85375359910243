import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '@wix/da-ds/pkg/Flex';
import DiscordIcon from '@wix/da-ds/pkg/Icons/24x24/Discord';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';

import s from './DiscordConnectedPage.scss';

export interface Props {}

const DiscordConnectedPage: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Flex className={s['root']} direction="column">
      <DiscordIcon
        size={IconSize.HUGE}
        className={s['icon']}
        disableDefaultColors
      />
      <p className={s['text']}>{t('discord.connected.page.body')}</p>
    </Flex>
  );
};
DiscordConnectedPage.displayName = 'DiscordConnectedPage';

export default DiscordConnectedPage;
