import React, { useCallback, useMemo } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import {
  BiData,
  SignupScreenClickContinueBiEvent,
} from '@wix/da-bi/pkg/events';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import { SocialLoginProvider } from '@wix/da-shared-react/pkg/SocialLogin/types';
import SocialButtonApple from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonApple';
import SocialButtonFacebook from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonFacebook';
import SocialButtonGoogle from '@wix/da-shared-react/pkg/SocialLogin/SocialButtonGoogle';
import PasswordInput from '../PasswordInput';
import JoinState from '../../../types/join';
import SocialLoginDivider from '../SocialLoginDivider';
import { getAuthMode } from '../../../helpers/bi';
import UsernameForm from '../UsernameForm';
import CompleteRedirectForm from '../CompleteRedirectForm';

import s from './JoinForm.scss';

export interface Props extends JoinState {
  changePassword(password: string): void;
  changeEmail(email: string): void;
  validateEmail(email: string): void;
  joinWithEmail(): void;
  errorDisplayed?(errorMessage): void;
}

const JoinForm: React.FC<Props> = ({
  email = '',
  emailError,
  joinMode,
  password = '',
  passwordError,
  passwordStrength,
  changePassword,
  changeEmail,
  validateEmail,
  errorDisplayed,
  joinWithEmail,
}) => {
  const { t, i18n } = useTranslation();

  const strength = (password && passwordStrength) || undefined;
  const errors = useMemo(
    () => ({
      email: emailError && (
        <>
          {t([`join.error.email.${emailError}`, 'join.error.email.invalid'])}{' '}
          {i18n.exists(`join.error.email.${emailError}.link.href`) && (
            <BiLink
              href={t(`join.error.email.${emailError}.link.href`)}
              className={s['error-link']}
            >
              {t(`join.error.email.${emailError}.link.label`)}
            </BiLink>
          )}
        </>
      ),
      password:
        !!(passwordError && !strength) &&
        t([`join.error.password.${passwordError}`, 'join.form.password.hint']),
    }),
    [emailError, passwordError, strength, t, i18n]
  );

  const onContinueWithEmail = useCallback(() => {
    joinWithEmail();
    const err: string[] = [];
    Object.keys(errors).forEach(key => {
      if (errors[key]) {
        err.push(errors[key]);
      }
    });
    if (err.length > 0 && errorDisplayed) {
      errorDisplayed(err.join(';'));
    }
  }, [joinWithEmail, errors, errorDisplayed]);

  if (joinMode) {
    return <UsernameForm />;
  }

  return (
    <div className={s['root']}>
      <React.Fragment key="social">
        <div className={s['social-buttons']}>
          <SocialButtonGoogle
            className={s['social-button']}
            biData={BiData<SignupScreenClickContinueBiEvent>({
              evid: 238,
              page_number: 1,
              auth_mode: getAuthMode(SocialLoginProvider.Google),
            })}
          />
          <SocialButtonApple
            className={s['social-button']}
            biData={BiData<SignupScreenClickContinueBiEvent>({
              evid: 238,
              page_number: 1,
              auth_mode: getAuthMode(SocialLoginProvider.Apple),
            })}
          >
            {t('join.form.button.continue.apple')}
          </SocialButtonApple>
          <SocialButtonFacebook
            className={s['social-button']}
            biData={BiData<SignupScreenClickContinueBiEvent>({
              evid: 238,
              page_number: 1,
              auth_mode: getAuthMode(SocialLoginProvider.Facebook),
            })}
          >
            {t('join.form.button.continue.facebook')}
          </SocialButtonFacebook>
        </div>
        <CompleteRedirectForm />
      </React.Fragment>
      <SocialLoginDivider key="divider" className={s['divider']} />
      <React.Fragment key="form">
        <div className={c(s['email'], emailError && s['field-error'])}>
          <TextInput
            id="email"
            name="email"
            label={t('join.form.email')}
            value={email}
            validationResult={errors.email ? 'error' : undefined}
            details={errors.email}
            autoFocus
            noBottomSpacing
            spellCheck={false}
            autoCapitalize="none"
            onChange={ev => changeEmail(ev.target.value.trim())}
            onBlur={() => {
              validateEmail(email);
            }}
          />
        </div>
        <div className={c(s['password'], errors.password && s['field-error'])}>
          <PasswordInput
            id="password"
            name="password"
            noBottomSpacing
            label={t('join.form.password')}
            className={s['password']}
            value={password}
            strength={strength}
            onChange={ev => changePassword(ev.target.value)}
            validationResult={errors.password ? 'error' : undefined}
            details={errors.password}
            autoComplete="new-password"
          />
        </div>

        <div className={s['password-hint']}>{t('join.form.password.hint')}</div>

        <BrandedButton
          // id="joinbutton"
          className={s['button']}
          size="large"
          variant="brand-green"
          type="button"
          onClick={onContinueWithEmail}
          fillContainerWidth
        >
          {t('join.form.button.continue.email')}
        </BrandedButton>
      </React.Fragment>
    </div>
  );
};
JoinForm.displayName = 'JoinForm';

export default JoinForm;
