import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import { refreshApp } from '../../../actions/app';
import { SisuPageProps } from './types';
import { getCancelUrl } from '../../../selectors/page';

type StateProps = Pick<
  SisuPageProps,
  | 'cardImageUrl'
  | 'cardDeviation'
  | 'cancelUrl'
  | 'baseDaUrl'
  | 'rootPath'
  | 'isOauth'
  | 'daProtect'
  | 'daDreamup'
  | 'appRefreshIsHardRefresh'
  | 'appRefreshIsLoginRedirect'
>;
type DispatchProps = Pick<SisuPageProps, 'refreshApp'>;
type OwnProps = Omit<SisuPageProps, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  cardImageUrl: state.cardImage,
  cardDeviation: state.cardDeviation,
  cancelUrl: getCancelUrl(state),
  baseDaUrl: state.baseDaUrl ?? '',
  rootPath: state.rootPath ?? '',
  isOauth: state.params.isOauth,
  daProtect: state.params.daProtect,
  daDreamup: state.params.daDreamup,
  appRefreshIsHardRefresh: state.appRefreshIsHardRefresh,
  appRefreshIsLoginRedirect: state.appRefreshIsLoginRedirect,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  refreshApp,
};

export default connect(mapStateToProps, mapDispatchToProps);
