import { URLS } from '@wix/da-url';
import { AppState } from '../types/store';

export const getCancelUrl = (state: AppState) =>
  state.params.cancelUrl || state.referer || state.baseDaUrl || URLS.ROOT;

export const getReferer = (state: AppState) => state.referer;

export const getKnownUrlParams = (
  state: AppState,
  headerReferer: boolean = false
) => {
  const params = new URLSearchParams();
  if (state.referer && state.referer !== state.baseDaUrl) {
    params.set('referer', state.referer);
  }
  if (headerReferer && state.headerReferer && state.headerReferer !== state.referer) {
    params.set('referer', state.headerReferer);
  }
  if (state.params.daProtect) {
    params.set('da_protect', '1');
  }
  if (state.params.daDreamup) {
    params.set('da_dreamup', '1');
  }
  const result = params.toString();
  return result ? `?${result}` : '';
};
export const getKnownUrlParamsWithHeaderReferer = (state: AppState) =>
  getKnownUrlParams(state, true);
