export type PasswordStrength = 'weak' | 'normal' | 'strong';

export default function passwordStrength(
  password: string,
  personalData?: string
) {
  // we are using a hybrid of our old, complex analysis and the new, simple check
  // weakness is determined by the simple check; strength by the advanced one
  const simple = passwordStrengthSimple(password);
  if (simple === 'weak') {
    return 'weak';
  }

  const advanced = passwordStrengthAdvanced(password, personalData);
  if (advanced === 'strong') {
    return 'strong';
  }

  return 'normal';
}

// The password strength checker specified by the specs http://k9pk3u.axshare.com/#g=1&p=password_strength
export function passwordStrengthSimple(password: string): PasswordStrength {
  const allNumber = /^\d+$/.test(password);
  const allLetter = /^[a-zA-Z]+$/.test(password);
  const hasSymbol = /[^A-Za-z0-9]/.test(password);
  const allLower = password.toLowerCase() === password;
  const allUpper = password.toUpperCase() === password;

  if (password.length < 6 || allNumber || allLetter) {
    return 'weak';
  }

  if (password.length > 6 || hasSymbol || !(allLower || allUpper)) {
    return 'strong';
  }

  return 'normal';
}

// our own, more detailed analyser... ported from old codebase
export function passwordStrengthAdvanced(password, personalData = '') {
  const analysis = new PasswordStrengthMeterAnalysis(password, personalData);
  const score = analysis.getScore();

  if (score > 75) {
    return 'strong';
  }

  if (score > 25) {
    return 'normal';
  }

  return 'weak';
}

class PasswordStrengthMeterAnalysis {
  // Set up a static cache for expanded sequences: this will be one long string
  static expandedSeqs = '';

  password = '';
  personalDataString = '';

  /**
        Common sequences of characteers, any 3 or more consecutive chars from any of
        these sequences will be considered to add no adittional security
    */
  sequences = [
    '0123456789',
    '`1234567890-=',
    '~!@#$%^&*()_+',
    'abcdefghijklmnopqrstuvwxyz',
    "qwertyuiop[]\\asdfghjkl;'zxcvbnm,./",
    'qwertyuiop{}|asdfghjkl:"zxcvbnm<>?',
    'qwertyuiopasdfghjklzxcvbnm',
    "1qaz2wsx3edc4rfv5tgb6yhn7ujm8ik,9ol.0p;/-['=]\\",
    'qazwsxedcrfvtgbyhnujmikolp',
  ];

  origLength: number;
  collapsedLength: number;
  passwordCollapsed: string;
  classes: any;
  effectiveCharset: number;

  constructor(password: string, personalData = '') {
    this.password = password;

    // Perform Basic analysis
    this.origLength = password.length;

    // Sequences are bad, heavy handed as it is we will just ignore 2 out of 3 sequential chars
    // as if they weren't there
    password = this.passwordCollapsed = this._collapse(password);

    // If personalData string is passed, we must mark anything that contains
    // 4 or more consecutive chars from this string
    this.personalDataString = personalData;

    this.collapsedLength = password.length;

    // Count character classes
    this.classes = {
      digit: (password.match(/\d/g) || []).length || 0,
      lowercase: (password.match(/[a-z]/g) || []).length || 0,
      uppercase: (password.match(/[A-Z]/g) || []).length || 0,
      other: (password.match(/[^0-9a-zA-Z]/g) || []).length || 0,
    };

    // Work out effective charset (assume that if the user hasn't used a number, they never would)
    // reducing their effective charset by 10
    this.effectiveCharset = 0;
    if (this.classes.digit) {
      this.effectiveCharset += 10;
    }
    if (this.classes.lowercase) {
      this.effectiveCharset += 26;
    }
    if (this.classes.uppercase) {
      this.effectiveCharset += 26;
    }
    if (this.classes.other) {
      // We assume that there are only about 20 non alphanumeric chars a user is likely to pick
      this.effectiveCharset += 20;
    }
  }

  getScore() {
    let score = 0;

    if (this.origLength < 6 || this.collapsedLength < 5) {
      return 0;
    }

    // Anything using personal data is inherently weak
    if (this.usesPersonalData()) {
      return 0;
    }

    // More length is good
    if (this.collapsedLength > 8) {
      score += 12.5;
    }
    if (this.collapsedLength > 10) {
      score += 12.5;
    }
    if (this.collapsedLength > 15) {
      score += 20;
    }

    // Both cases except for initial capital
    if (this.classes.uppercase && this.classes.lowercase && !this.ucIsFirst()) {
      score += 12.5;
    }

    // Digits
    if (this.classes.digit) {
      if (this.digitsAreExtreme()) {
        score += 10;
      } else {
        // + 25 for the first, +8 for each other number
        score +=
          this.classes.digit > 1 ? 25 + 8 * (this.classes.digit - 1) : 20;
      }
    }

    // Symbols
    if (this.classes.other) {
      if (this.symbolsAreExtreme()) {
        score += 10;
      } else {
        // + 25 for the first, +8 for more
        score +=
          this.classes.other > 1 ? 25 + 8 * (this.classes.other - 1) : 25;
      }
    }

    // Limit output range
    return Math.min(Math.max(0, score), 100);
  }

  /*
        Optional analyses
    */
  // Flag any use of passed personal data as this is likely to be pretty weak
  // We consider any 4 consecutive chars in password to be use
  usesPersonalData() {
    for (let i = 0; i <= this.password.length - 4; i++) {
      let substring = this.password.substring(i, i + 4).toLowerCase();
      if (this.personalDataString.indexOf(substring) > -1) {
        // Personal data used in password
        return true;
      }
    }
    return false;
  }

  // Flag up the case where there is only one uppercase char and it is at the beggining
  // since this adds very little security
  ucIsFirst() {
    let firstChar;
    if (this.classes.uppercase === 1) {
      firstChar = this.password.charAt(0);
      if (firstChar === firstChar.toUpperCase()) {
        return true;
      }
    }
    return false;
  }
  // Flag up all digits being at the start or end
  digitsAreExtreme() {
    let stripped;
    if (this.classes.digit) {
      // Strip digits from beginning and end
      stripped = this.passwordCollapsed.replace(/^\d+|\d+$/g, '');
      // Check if there are any digits left - stripped length will equal
      // password length - num of digits
      return stripped.length === this.collapsedLength - this.classes.digit;
    }
    return false;
  }
  // Flag up all symbols being at the start or end
  symbolsAreExtreme() {
    let stripped;
    if (this.classes.other) {
      // Strip symbols from beginning and end
      stripped = this.passwordCollapsed.replace(
        /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g,
        ''
      );
      // Check if there are any symbols left - stripped length will equal
      // password length - num of symbols
      return stripped.length === this.collapsedLength - this.classes.other;
    }
    return false;
  }
  // Work out the total number of permutations based on this password length and
  // effective charset. We use the real length not collapsed as we want are not so
  // interested in how random or otherwise the chars are here
  possiblePermutations() {
    return Math.pow(this.effectiveCharset, this.origLength);
  }
  distinctChars() {
    return this._distinctChars(this.passwordCollapsed);
  }
  expectedDistinctChars() {
    return this._expectedDistinctChars(
      this.effectiveCharset,
      this.collapsedLength
    );
  }

  /*
        'Static' Helper functions
    */
  _collapse(password) {
    return this._collapseRepeats(this._collapseSequences(password));
  }
  /* Collapse common sequeces of chars so they are not considered to add additional security */
  _collapseSequences(password) {
    if (!PasswordStrengthMeterAnalysis.expandedSeqs.length) {
      // First we prepare the sequences by joining
      PasswordStrengthMeterAnalysis.expandedSeqs = this.sequences.join('');
      // Then add the same again reversed to catch 321 etc.
      PasswordStrengthMeterAnalysis.expandedSeqs += this._reverseString(
        PasswordStrengthMeterAnalysis.expandedSeqs
      );
    }

    // RATIONALE: common sequences are very bad and add little security.
    // To account for this we check each 3 char substring of password and if
    // we find it is sequential, we reduce it to just it's first char
    // Since this may remove character classes it is potentially quite
    // heavy handed but we are only doing a best guess of whether it is a
    // good password and most things containing 123 are poor.
    // Longer sequential substrings re not considered for simplicity and
    // to avoid penalising too much. i.e. 123456789 would be reduced to 147

    // Loop through password to find any sequences
    for (let i = 0; i <= password.length - 3; i++) {
      let substring = password.substring(i, i + 3).toLowerCase();
      if (PasswordStrengthMeterAnalysis.expandedSeqs.indexOf(substring) > -1) {
        // This is the start of a 3 (or more) char sequence
        // Remove the next two chars before we continue checking
        password = password.substring(0, i + 1) + password.substring(i + 3);
      }
    }
    return password;
  }
  _collapseRepeats(password, repeat_length?) {
    let c;
    let repeatCount = 0;

    for (let i = 0; i < password.length; i++) {
      if (c === password.charAt(i)) {
        repeatCount++;

        if (repeatCount > 1) {
          // remove this char and move pointer back
          password = password.substring(0, i) + password.substring(i + 1);
          i--;
        }
      } else {
        repeatCount = 0;
      }
      c = password.charAt(i);
    }

    return password;
  }
  /*
        Calculates the expected number of distinct chars for a random
        string of length l, given a charset of size d
    */
  _expectedDistinctChars(d, n) {
    const expectedRepeats = Math.floor(n - d + d * Math.pow((d - 1) / d, n));
    // Expected distinct chars is the length - number of repeats
    return n - expectedRepeats;
  }

  /* Count distinct chars in a string */
  _distinctChars(string) {
    let c,
      distinctChars = '';
    for (let i = 0; i < string.length; i++) {
      c = string.charAt(i);
      if (distinctChars.indexOf(c) < 0) {
        distinctChars += c;
      }
    }
    return distinctChars.length;
  }
  _reverseString(string) {
    return string
      .split('')
      .reverse()
      .join('');
  }
}
