import {
  TOPIC_SELECT,
  TOPIC_UNSELECT,
  TOPIC_LIST_UPDATE,
} from '../actions/topic';
import TopicState from '../types/topic';

const defaultState: TopicState = {
  available: [],
  suggested: [],
  history: [],
  selections: [],
  count: 0,
  selected: 0,
};

export default function topic(state: TopicState = defaultState, action) {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true };
  }

  switch (action.type) {
    case TOPIC_SELECT:
      return topicSelect(state, action);
    case TOPIC_UNSELECT:
      return topicUnselect(state, action);
    case TOPIC_LIST_UPDATE:
      return topicListUpdate(state, action);
    default:
      return state;
  }
}

function topicSelect(state: TopicState, action) {
  const newSelections: number[] = [...state.selections, action.newTopicId];

  return {
    ...state,
    selections: newSelections,
    selected: newSelections.length,
  };
}

function topicUnselect(state: TopicState, action) {
  const newSelections: number[] = state.selections.filter(
    id => action.oldTopicId !== id
  );

  return {
    ...state,
    selections: newSelections,
    selected: newSelections.length,
  };
}

function topicListUpdate(state: TopicState, action) {
  const newHistory: number[][] = [...state.history, action.newTopicIds];

  return {
    ...state,
    available: action.availableTopics,
    suggested: action.newTopicIds,
    count: action.availableTopics.length,
    history: newHistory,
  };
}
