import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './UsernameForm';
import { AppState } from '../../../types/store';
import {
  changeUsername,
  changeDateOfBirth,
  attemptSubmit2,
} from '../../../actions/join';

type StateProps = Pick<Props, keyof AppState['join'] | 'isOauth'>;
type DispatchProps = Pick<
  Props,
  'changeUsername' | 'changeDateOfBirth' | 'attemptSubmit'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  ...state.join,
  isOauth: state.params.isOauth,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  changeUsername,
  changeDateOfBirth,
  attemptSubmit: attemptSubmit2,
};

export default connect(mapStateToProps, mapDispatchToProps);
