import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { SisuPageProps } from './types';
import MobileSisuPage from './MobileSisuPage';
import DesktopSisuPage from './DesktopSisuPage';

const SisuPage: React.FC<SisuPageProps> = props => {
  const isMobile = useContext(MobileContext);
  return isMobile ? (
    <MobileSisuPage {...props} />
  ) : (
    <DesktopSisuPage {...props} />
  );
};

export default SisuPage;
