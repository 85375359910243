import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingPage from '../../SignupSignin/OnboardingPage';
import IntentCard from './IntentCard';

import s from './IntentsPage.scss';

const INTENTS_ORDER = ['discover', 'community', 'buy', 'improve', 'ai', 'sell'];

export interface Props {
  authMode: string;
  toggleIntent(option, authMode, newState): void;
}

export const IntentsPage: React.FC<Props> = ({ authMode, toggleIntent }) => {
  const { t } = useTranslation();

  // maintain list of selected here so we know when we can enable
  // the continue button
  const [selectedIntents, setSelectedIntents] = useState<any[]>([]);
  const onIntentClick = intentId => {
    const isBeingToggledOff = selectedIntents.includes(intentId);
    const updatedSelectedIntents = isBeingToggledOff
      ? selectedIntents.filter(id => id !== intentId)
      : [...selectedIntents, intentId];

    setSelectedIntents(updatedSelectedIntents);
    toggleIntent(intentId, authMode, !isBeingToggledOff);
  };

  return (
    <OnboardingPage
      pageTitle={t('onboarding.intents.page.title')}
      heading={t('intents.title')}
      canContinue={selectedIntents.length >= 1}
      postFormTo="/_sisu/do/saveintents"
    >
      <div className={s['intents']}>
        {INTENTS_ORDER.map(intentId => {
          const isSelected = selectedIntents.includes(intentId);
          return (
            <IntentCard
              key={intentId}
              className={s['intent']}
              intentId={intentId}
              inputName="intents[]"
              selected={isSelected}
              onClick={onIntentClick}
            />
          );
        })}
      </div>
    </OnboardingPage>
  );
};

export default IntentsPage;
