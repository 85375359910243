import LoginState from '../types/login';
import { APP_REFRESH } from '../actions/app';
import {
  LOGIN_CHANGE_USERNAME,
  LOGIN_CHANGE_PASSWORD,
  LOGIN_CHANGE_REMEMBER,
} from '../actions/login';

const defaultState: LoginState = {
  username: '',
  password: '',
  showCaptcha: false,
  remember: true,
  usernameInvalid: false,
  passwordInvalid: false,
  generalError: '',
  errorType: '',
  tfaUserData: undefined,
  tfaVerificationCodeInvalid: false,
  tfaRecoveryCodeInvalid: false,
};

export default function login(state = defaultState, action): LoginState {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true };
  }

  switch (action.type) {
    case LOGIN_CHANGE_USERNAME:
      return changeUsername(state, action);
    case LOGIN_CHANGE_PASSWORD:
      return changePassword(state, action);
    case LOGIN_CHANGE_REMEMBER:
      return changeRemember(state, action);
    case APP_REFRESH:
      return appRefresh(state);
    default:
      return state;
  }
}

function changeUsername(state: LoginState, action) {
  return {
    ...state,
    username: action.username,
    usernameInvalid: false,
  };
}

function changePassword(state: LoginState, action) {
  return {
    ...state,
    password: action.password,
    passwordInvalid: false,
  };
}

function changeRemember(state: LoginState, action) {
  return {
    ...state,
    remember: action.remember,
  };
}

function appRefresh(state: LoginState) {
  return {
    ...state,
    ...defaultState,
  };
}
