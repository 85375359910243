import React from 'react';
import c from 'classnames';
import s from './PageErrorMessage.scss';

export interface Props {
  className?: string;
  children: React.ReactNode;
}

const PageErrorMessage: React.FC<Props> = ({ className, children }) => {
  return <div className={c(s['root'], className)}>{children}</div>;
};
PageErrorMessage.displayName = 'PageErrorMessage';

export default PageErrorMessage;
