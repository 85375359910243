import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLS, Url } from '@wix/da-url';
import Logo from '@wix/da-ds/pkg/Logo';
import Button from '@wix/da-shared-react/pkg/Button';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
} from '../SisuPage';

import s from './LogoutOauthPage.scss';

export interface Props {
  csrfToken?: string;
}

const LogoutOauthPage: React.FC<Props> = ({ csrfToken }) => {
  const { t } = useTranslation();

  return (
    <SisuPage
      className={s['page']}
      bodyClassName={s['body']}
      cardClassName={s['card']}
      title={t('logout.page.title')}
      origin="/users/login"
      withHero={false}
      withLogo={false}
    >
      <div className={s['logo']}>
        <Logo size="tiny" />
      </div>
      <SisuPageContent className={s['content']}>
        <form method="POST" action={URLS.LOGOUT}>
          <input
            type="hidden"
            name="validate_token"
            value={csrfToken}
            autoComplete="off"
          />
          <SisuPageHeader className={s['title']}>
            {t('logout.title')}
          </SisuPageHeader>
          <SisuPageSubHeader>{t('logout.subtitle')}</SisuPageSubHeader>
          <div className={s['actions']}>
            <Button className={s['button']} type="submit">
              {t('logout.submit')}
            </Button>
            <Button
              view="b14"
              className={s['button']}
              href={Url.browseHomeLink()}
            >
              {t('common.cancel')}
            </Button>
          </div>
        </form>
      </SisuPageContent>
    </SisuPage>
  );
};

export default LogoutOauthPage;
