import React from 'react';
import TextInput, {
  type TextInputProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import s from './PasswordInput.scss';

export interface Props extends TextInputProps {
  strength?: 'weak' | 'normal' | 'strong';
}

const PasswordInput = React.forwardRef<HTMLInputElement, Props>(
  ({ strength, ...restProps }, ref) => {
    let suffix;
    if (strength) {
      suffix = <span className={s[`strength-${strength}`]}>{strength}</span>;
    }
    return (
      <TextInput type="password" {...restProps} postfix={suffix} ref={ref} />
    );
  }
);
PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
