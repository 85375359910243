import React from 'react';

export interface RecaptchaProps {
  siteKey: string;
  onRecaptchaSubmit: (string) => void;
  invisible?: boolean;
  tabIndex?: number;
  theme?: 'dark' | 'light';
  size?: 'compact' | 'normal';
}

export default class Recaptcha extends React.Component<RecaptchaProps> {
  libAdded = false;

  componentDidMount() {
    this.createCallback();
    this.loadLib();
  }

  createCallback() {
    // this doesn't support multiple recaptchas on one page
    // but let's hope we won't ever need that...
    window.onRecaptchaSubmit = token => {
      this.props.onRecaptchaSubmit(token);
    };
  }

  loadLib() {
    if (this.libAdded) {
      return;
    }

    if (document) {
      const existing = document.getElementById('recaptcha-lib');
      if (existing) {
        this.libAdded = true;
        return;
      }
      const headElement = document.getElementsByTagName('head')[0];
      if (!headElement) {
        return;
      }

      const jsElement = document.createElement('script');
      jsElement.id = 'recaptcha-lib';
      jsElement.src = 'https://www.google.com/recaptcha/api.js';
      jsElement.async = true;
      jsElement.defer = true;
      headElement.appendChild(jsElement);
      this.libAdded = true;
    }
  }

  execute() {
    window.grecaptcha && window.grecaptcha.execute();
  }

  renderInvisible() {
    return (
      <div
        className="g-recaptcha"
        data-sitekey={this.props.siteKey}
        data-callback="onRecaptchaSubmit"
        data-size="invisible"
      />
    );
  }

  renderWidget() {
    return (
      <div
        className="g-recaptcha"
        data-callback="onRecaptchaSubmit"
        data-sitekey={this.props.siteKey}
        data-theme={this.props.theme}
        data-size={this.props.size}
        data-tabindex={this.props.tabIndex}
      />
    );
  }

  render() {
    return this.props.invisible ? this.renderInvisible() : this.renderWidget();
  }
}
