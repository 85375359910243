import React from 'react';
import c from 'classnames';
import DateDropdowns from '@wix/da-ds/pkg/Dropdown/Preset/DateDropdowns';
import s from './DateOfBirth.scss';

export interface Props {
  monthValue?: number;
  dayValue?: number;
  yearValue?: number;
  label?: string;
  hint?: string;
  className?: string;
  onSelect?(field, value): void;
  errorMessage?: string;
}

export const DateOfBirth: React.FC<Props> = ({ className, ...props }) => {
  return (
    <DateDropdowns
      namePrefix="dob"
      className={c(s['root'], className)}
      textUnderClassName={s['field-text-under']}
      dropdownFieldClassName={s['dropdown-field']}
      forceNativeDropdown
      {...props}
    />
  );
};
DateOfBirth.displayName = 'DateOfBirth';

export default DateOfBirth;
