import { SocialLoginProvider } from '@wix/da-shared-react/pkg/SocialLogin/types';

const providers: { [key in SocialLoginProvider]: string } = {
  [SocialLoginProvider.Google]: 'google',
  [SocialLoginProvider.Apple]: 'apple',
  [SocialLoginProvider.Facebook]: 'facebook',
  [SocialLoginProvider.Test]: 'test',
};

export function getAuthMode(provider: SocialLoginProvider | undefined) {
  if (!provider) {
    return undefined;
  }
  return providers[provider];
}
