import { connect, MapStateToProps } from 'react-redux';
import { Props } from './ResetPasswordPage';
import { AppState } from '../../../types/store';
import { RouteComponentProps } from 'react-router';

type StateProps = Pick<Props, 'username' | 'passkey' | 'errorCode'>;
type OwnProps = Omit<Props, keyof StateProps> &
  RouteComponentProps<{ username?: string; passkey?: string }>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  { match }
) => {
  return {
    username: state.resetPassword.username || match.params.username,
    passkey: state.resetPassword.passkey || match.params.passkey,
    errorCode: state.resetPassword.passwordErrorCode,
  };
};

export default connect(mapStateToProps);
