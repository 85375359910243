import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import Logo from '@wix/da-ds/pkg/Logo';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import Button from '@wix/da-shared-react/pkg/Button';
import CheckIcon from '@wix/da-ds/pkg/Icons/Check';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import Checkbox from '@wix/da-ds/pkg/Checkbox';
import SisuPage, { SisuPageContent, SisuPageFooter } from '../SisuPage';
import SisuForm from '../../SignupSignin/SisuForm';
import { PERMISSION_LABEL_KEYS } from './constants';
import s from './AppAuthorizationPage.scss';

const SKETCHBOOK_CLIENT_ID = '528';

export interface AppAuthorizationPageProps {
  appDeviation?: PapiDeviation;
  requestedScope: string;
}

const AppAuthorizationPage: React.FC<
  AppAuthorizationPageProps & RouteComponentProps
> = ({ appDeviation, requestedScope, location }) => {
  const { t } = useTranslation();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [error, setError] = useState();
  const generalError = undefined;
  const params = new URLSearchParams(location.search);
  const clientId = params.get('client_id');
  const requestedPermissions = requestedScope.split(' ');
  const permissionLabelKeys = requestedPermissions.reduce<string[]>(
    (allKeys, name) => {
      const permissionKeys = PERMISSION_LABEL_KEYS[name] || [];
      const dedupedKeys = permissionKeys.filter(k => !allKeys.includes(k));
      return allKeys.concat(dedupedKeys);
    },
    []
  );

  const handleSubmit = (e: React.FormEvent) => {
    if (clientId === SKETCHBOOK_CLIENT_ID && !termsAgreed) {
      e.preventDefault();
      setError(t('oauth.appAuthorization.termsError'));
    }
  };

  return (
    <SisuPage
      className={classnames(s['page'], 'no-theme-skin')}
      cardClassName={s['card']}
      bodyClassName={s['body']}
      origin="/users/login"
      errorTextKey="login"
      errorType={generalError}
      withHero={false}
      withLogo={false}
    >
      <SisuPageContent className={s['content']}>
        <Logo className={s['logo']} size="tiny" />
        <SisuForm
          action={`/_sisu/do/authorize_app`}
          method="post"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="client_id" value={clientId || ''} />
          <input
            type="hidden"
            name="response_type"
            value={params.get('response_type') || ''}
          />
          <input
            type="hidden"
            name="redirect_uri"
            value={params.get('redirect_uri') || ''}
          />
          <input type="hidden" name="scope" value={params.get('scope') || ''} />
          <input type="hidden" name="state" value={params.get('state') || ''} />
          <input type="hidden" name="authorized" value="1" />
          {appDeviation && (
            <div className={s['deviation']}>
              <DeviationDumbView
                className={s['deviation-dumbo']}
                deviation={appDeviation}
                width={100}
              />
              <div className={s['deviation-details']}>
                <span className={s['deviation-title']}>
                  {appDeviation.title}
                </span>
                {appDeviation.author && (
                  <span className={s['deviation-username']}>
                    <Trans i18nKey="oauth.appAuthorization.author">
                      Published by{' '}
                      <UserLink
                        className={s['deviation-user-link']}
                        user={appDeviation.author}
                      />
                    </Trans>
                  </span>
                )}
              </div>
            </div>
          )}
          <h1 className={s['title']}>{t('oauth.appAuthorization.title')}</h1>
          <div className={s['permissions-heading']}>
            {t('oauth.appAuthorization.permissions')}
          </div>
          <ul className={s['permissions-list']}>
            {permissionLabelKeys.map((labelKey, idx) => (
              <li key={idx}>
                <CheckIcon
                  className={s['permissions-check-icon']}
                  size={IconSize.TINY}
                />
                {t(labelKey)}
              </li>
            ))}
          </ul>
          {clientId === SKETCHBOOK_CLIENT_ID && (
            <div className={s['terms-checkbox']}>
              <Checkbox
                labelClassName={s['terms-checkbox-label']}
                name="terms_agree"
                isChecked={termsAgreed}
                onChange={checked => setTermsAgreed(checked)}
              >
                {t('oauth.appAuthorization.sketchbook.terms')}
              </Checkbox>
              {error && <div className={s['error-message']}>{error}</div>}
            </div>
          )}
          <Button
            type="submit"
            className={s['button']}
            view="b1-1"
            height="large"
            fullWidth
          >
            {t('oauth.appAuthorization.authorize')}
          </Button>
          <Button
            href={Url.browseHomeLink()}
            className={s['button']}
            view="b14-1"
            height="large"
            fullWidth
          >
            {t('oauth.appAuthorization.deny')}
          </Button>
        </SisuForm>
      </SisuPageContent>
      <SisuPageFooter>
        {t('oauth.appAuthorization.terms', { title: appDeviation?.title })}
      </SisuPageFooter>
    </SisuPage>
  );
};

export default AppAuthorizationPage;
