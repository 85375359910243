// DS: CR58B
/** An image link in card form that displays art and title
 *  associated with a topic, used in SISU onboarding.
 */
import React from 'react';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import Checkbox from '@wix/da-ds/pkg/Checkbox';
import DeviationDumbo from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import { BiData, TopicsOnboardingSelecBiEventt } from '@wix/da-bi/pkg/events';

import s from './OnboardingTopicCard.scss';

export interface Props {
  /** resource type for topics */
  typeId?: number;
  /** neco id for this topic */
  itemId?: number;
  /** the topic name (e.g. 'Digital Art') */
  name: string;
  /** deviation used for a cover */
  coverDeviation?: PapiDeviation;
  /** callback to use */
  onClick?: (string, number?) => void;
  isSelected?: boolean;
  width: number;
  className?: string;
  rank?: number;
}

const OnboardingTopicCard: React.FC<Props> = ({
  onClick,
  name,
  coverDeviation,
  width,
  className,
  isSelected,
  typeId,
  itemId,
  rank,
}) => {
  if (!onClick || !name || !width) {
    return null;
  }

  const linkStyles = { width, height: width };
  return (
    <PlainButtonOrLink
      style={linkStyles}
      className={classnames(
        s['root'],
        className,
        !coverDeviation && s['missing-bg'],
        isSelected && s['selected']
      )}
      tabIndex={0}
      biData={BiData<TopicsOnboardingSelecBiEventt>({
        evid: 236,
        view: 'login',
        component: 'onboarding',
        typeid: typeId ?? 0,
        itemid: itemId ?? 0,
        topic: name,
        rank: rank ?? 0,
        is_selected: isSelected ? 0 : 1, // clicking inverts
        deviationid: coverDeviation ? coverDeviation.deviationId : 0,
      })}
      onClick={() => onClick(name, itemId)}
    >
      <div className={s['deviation-wrapper']} style={{ width, height: width }}>
        {coverDeviation && (
          <DeviationDumbo
            className={s['deviation']}
            deviation={coverDeviation}
            key={coverDeviation.title}
            width={width}
            height={width}
          />
        )}
      </div>
      <div className={s['overlay']} />
      <div className={s['name']}>{name}</div>
      {isSelected && (
        <Checkbox className={s['selected-tick']} tabIndex={-1} isChecked />
      )}
    </PlainButtonOrLink>
  );
};
OnboardingTopicCard.displayName = 'OnboardingTopicCard';
export default OnboardingTopicCard;
