import React from 'react';
import { PapiUser } from '@wix/da-papi-types';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import BasicUserName from '@wix/da-shared-react/pkg/User/BasicUserName';

import s from './MiniUserDisplay.scss';

interface Props {
  user: PapiUser;
}

const UserDisplay: React.FC<Props> = ({ user }) => {
  const tagline = user.profile?.tagline;
  return (
    <div className={s['root']}>
      <UserAvatar user={user} size={50} withLink={false} />
      <div className={s['details']}>
        <div className={s['username']}>
          <BasicUserName user={user} />
        </div>
        {tagline && <span className={s['tagline']}>{tagline}</span>}
      </div>
    </div>
  );
};
UserDisplay.displayName = 'UserDisplay';

export default UserDisplay;
