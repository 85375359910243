import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import {
  topicSelect,
  topicUnselect,
  topicSuggestions,
} from '../../../actions/topic';
import { Props } from './TopicsPage';
import { SisuPageProps } from '../SisuPage/types';

type StateProps = Pick<
  Props,
  | 'referer'
  | 'allTopics'
  | 'topicIdHistory'
  | 'topicCount'
  | 'selectedTopicIds'
  | 'selectedTopicCount'
>;
type DispatchProps = Pick<
  Props,
  'topicSelect' | 'topicUnselect' | 'topicSuggestions'
>;
type OwnProps = Omit<Props & SisuPageProps, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    referer: state.referer,
    allTopics: state.topic.available,
    suggestedTopicIds: state.topic.suggested,
    topicIdHistory: state.topic.history,
    topicCount: state.topic.count,
    selectedTopicIds: state.topic.selections,
    selectedTopicCount: state.topic.selected,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  topicSelect,
  topicUnselect,
  topicSuggestions,
};

export default connect(mapStateToProps, mapDispatchToProps);
