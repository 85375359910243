import JoinState from '../types/join';
import { APP_REFRESH } from '../actions/app';
import {
  JOIN_CHANGE_USERNAME,
  JOIN_FETCH_USERNAME_AVAILABLE,
  JOIN_CHANGE_PASSWORD,
  JOIN_CHANGE_PASSWORD2,
  JOIN_CHANGE_EMAIL,
  JOIN_CHANGE_EMAIL2,
  JOIN_CHANGE_DOB,
  JOIN_CHANGE_USERNAME_AVAILABLE,
  JOIN_CHANGE_STEP,
  JOIN_VALIDATE_EMAIL,
  JOIN_VALIDATE_CONFIRM_EMAIL,
  JOIN_ATTEMPT_SUBMIT,
  setJoinMode,
  joinWithEmail,
  attemptSubmit2 as attemptSubmit2Action,
} from '../actions/join';
import calculatePasswordStrength from '../helpers/passwordStrength';
import logger from '../helpers/logger';
import { setSocialLoginTokenId } from '../actions/social';

const defaultState: JoinState = {
  username: '',
  password: '',
  password2: '',
  email: '',
  email2: '',
  step: 0,
  attemptedSubmit: false,
  dobYear: null,
  dobMonth: null,
  dobDay: null,
  usernameFetching: false,
  passwordWeaknesses: [],
  passwordStrength: null,
  showUsernameAvailable: false,
  usernameError: null,
  passwordError: null,
  password2Error: null,
  emailError: null,
  email2Error: null,
  dobError: null,
  generalError: '',
};

export default function join(state = defaultState, action): JoinState {
  if (!state.hydrated) {
    state = { ...defaultState, ...state, hydrated: true };
  }

  switch (action.type) {
    case JOIN_CHANGE_USERNAME:
      return changeUsername(state, action);
    case JOIN_FETCH_USERNAME_AVAILABLE:
      return fetchUsernameAvailable(state);
    case JOIN_CHANGE_PASSWORD:
      return changePassword(state, action);
    case JOIN_CHANGE_PASSWORD2:
      return changePassword2(state, action);
    case JOIN_CHANGE_EMAIL:
      return changeEmail(state, action);
    case JOIN_VALIDATE_EMAIL:
      return validateEmail(state, action);
    case JOIN_VALIDATE_CONFIRM_EMAIL:
      return validateConfirmEmail(state, action);
    case JOIN_CHANGE_EMAIL2:
      return changeEmail2(state, action);
    case JOIN_CHANGE_DOB:
      return changeDateOfBirth(state, action);
    case JOIN_CHANGE_USERNAME_AVAILABLE:
      return changeUsernameAvailable(state, action);
    case JOIN_CHANGE_STEP:
      return changeStep(state, action);
    case JOIN_ATTEMPT_SUBMIT:
      return attemptSubmit(state, action);
    case APP_REFRESH:
      return appRefresh(state);
    case `${setJoinMode}`:
      return {
        ...state,
        joinMode: action.payload.mode,
      };
    case `${joinWithEmail}`:
      return attemptJoinWithEmail(state, action);
    case `${attemptSubmit2Action}`:
      return attemptSubmit2(state, action);
    case `${setSocialLoginTokenId}`:
      return {
        ...state,
        socialLoginProvider: action.payload.provider,
        socialLoginTokenId: action.payload.tokenId,
      };
    default:
      return state;
  }
}

function isValidEmail(email: string) {
  return /^([^@"]+)@(.+\..+)$/.test(email);
}

function checkPassword(
  state: JoinState,
  password: string
): Pick<
  JoinState,
  'passwordError' | 'passwordStrength' | 'passwordWeaknesses'
> {
  const personalData = `${state.username}  ${state.email}  ${state.dobYear}  ${state.dobMonth}  ${state.dobDay}`;
  const passwordStrength = calculatePasswordStrength(password, personalData) as
    | 'weak'
    | 'normal'
    | 'strong';

  let { passwordWeaknesses } = state;
  let passwordError: any = null;

  if (!password) {
    passwordError = 'missing';
  } else if (passwordStrength === 'weak') {
    if (passwordWeaknesses && passwordWeaknesses.length > 0) {
      passwordError = passwordWeaknesses[0];
    } else {
      passwordError = 'weak';
    }
  } else {
    // reset password weaknesses once it's no longer weak
    passwordWeaknesses = [];
  }

  return {
    passwordStrength,
    passwordError,
    passwordWeaknesses,
  };
}

function changeUsername(state: JoinState, action): JoinState {
  const { username } = action;

  if (username.length > 20) {
    return state;
  }

  let usernameError: any = null;

  if (!username) {
    // only show error after attempted submit
    if (state.attemptedSubmit) {
      usernameError = 'missing';
    }
  } else if (
    !/^[A-Za-z0-9-]+$/.test(username) ||
    username[0] === '-' ||
    username[username.length - 1] === '-'
  ) {
    usernameError = 'invalid';
  } else if (state.attemptedSubmit && username.length < 3) {
    usernameError = 'length_short';
  }

  return {
    ...state,
    username,
    usernameError,
  };
}

function fetchUsernameAvailable(state: JoinState) {
  return {
    ...state,
    usernameFetching: true,
  };
}

function changePassword(state: JoinState, action): JoinState {
  const passwordDetails = checkPassword(state, action.password);
  if (passwordDetails.passwordError === 'missing' && !state.attemptedSubmit) {
    // only show this error after attempted submit
    passwordDetails.passwordError = null;
  }
  return {
    ...state,
    password: action.password,
    ...passwordDetails,
    password2Error: validateConfirmationField(
      action.password,
      state.password2,
      state.attemptedSubmit
    )
      ? null
      : 'mismatch',
  };
}

function changePassword2(state: JoinState, action): JoinState {
  return {
    ...state,
    password2: action.password,
    password2Error: validateConfirmationField(
      state.password,
      action.password,
      state.attemptedSubmit
    )
      ? null
      : 'mismatch',
  };
}

function changeEmail(state: JoinState, action): JoinState {
  return {
    ...state,
    email: action.email,
    emailError: null,
  };
}

function validateEmail(state: JoinState, action): JoinState {
  let emailError: any = null;
  if (!action.email) {
    // only show error after attempted submit
    if (state.attemptedSubmit) {
      emailError = 'missing';
    }
  } else if (!isValidEmail(action.email)) {
    emailError = 'invalid';
  } else if (state.emailError === 'in_use') {
    emailError = 'in_use';
  }

  return {
    ...state,
    emailError,
  };
}

function changeEmail2(state: JoinState, action): JoinState {
  return {
    ...state,
    email2: action.email,
    email2Error: null,
  };
}

function validateConfirmEmail(state: JoinState, action): JoinState {
  return {
    ...state,
    email2Error: validateConfirmationField(
      action.email,
      action.confirmEmail,
      state.attemptedSubmit
    )
      ? null
      : 'mismatch',
  };
}

function changeDateOfBirth(state: JoinState, action): JoinState {
  const minAge = 13;

  // need to be number for correct behavior
  const value = parseInt(action.value, 10);

  const dobYear = action.part === 'year' ? value : state.dobYear;
  const dobMonth = action.part === 'month' ? value : state.dobMonth;
  const dobDay = action.part === 'day' ? value : state.dobDay;

  let dobError: any = null;

  // only worth checking if everything is filled in
  if (dobYear && dobMonth && dobDay) {
    // JS month start at zero
    const dob = new Date(dobYear, dobMonth - 1, dobDay);
    // test the validity of date
    if (!dob || dob.getMonth() + 1 !== dobMonth || dob.getDate() !== dobDay) {
      dobError = 'invalid';
    }
    if (!dobError) {
      const now = new Date();

      let age = now.getFullYear() - dob.getFullYear(); // first, naive year difference
      dob.setFullYear(now.getFullYear()); // set to birthday in current year
      // if birthday has not occured yet this year, deduct one year
      if (now < dob) {
        age--;
      }

      if (age < minAge) {
        dobError = 'underage';
      }
    }
  } else if (state.attemptedSubmit) {
    dobError = 'missing';
  }

  return {
    ...state,
    dobYear,
    dobMonth,
    dobDay,
    dobError,
  };
}

function changeUsernameAvailable(state: JoinState, action): JoinState {
  // result is no longer relevant
  if (action.username !== state.username) {
    return state;
  }

  let usernameError = state.usernameError;
  if (!usernameError && !action.available) {
    usernameError = 'unavailable';
  }

  return {
    ...state,
    username: action.username,
    usernameError,
    showUsernameAvailable: action.available,
    usernameFetching: false,
  };
}

function changeStep(state: JoinState, action) {
  switch (action.step) {
    case 0: // first page
      // always allow going back
      return {
        ...state,
        step: 0,
        attemptedSubmit: false,
      };
    case 1: // normal join page, second step
      if (
        // need first page to have correct username and email
        state.username &&
        state.email &&
        state.email2 &&
        !state.usernameError &&
        !state.emailError &&
        !state.email2Error
      ) {
        return {
          ...state,
          step: 1,
          attemptedSubmit: false,
        };
      }
      break;
    default:
  }

  return {
    ...state,
    attemptedSubmit: true,
  };
}

function attemptSubmit(state: JoinState, action) {
  let passwordError = state.passwordError;
  if (!passwordError) {
    if (!state.password) {
      passwordError = 'missing';
    } else if (state.passwordStrength === 'weak') {
      passwordError = 'weak';
    }
  }

  return {
    ...state,
    attemptedSubmit: true,
    usernameError:
      state.usernameError ||
      (state.username ? null : 'missing') ||
      (state.username!.length < 3 ? 'length_short' : null),
    emailError: state.emailError || (state.email ? null : 'missing'),
    passwordError,
    dobError:
      state.dobError ||
      (state.dobMonth && state.dobYear && state.dobDay ? null : 'missing'),
  };
}

function attemptJoinWithEmail(
  state: JoinState,
  action: ReturnType<typeof joinWithEmail>
): JoinState {
  let emailError: JoinState['emailError'] = null;
  if (!state.email) {
    emailError = 'missing';
  } else if (!isValidEmail(state.email)) {
    emailError = 'invalid';
  }
  logger.log('email validation result ', emailError);
  return {
    ...state,
    emailError: state.emailError || emailError,
    ...checkPassword(state, state.password || ''),
  };
}

function attemptSubmit2(
  state: JoinState,
  action: ReturnType<typeof attemptSubmit2Action>
) {
  return {
    ...state,
    usernameError:
      state.usernameError ||
      (state.username ? null : 'missing') ||
      (state.username!.length < 3 ? 'length_short' : null),
    dobError:
      state.dobError ||
      (state.dobMonth && state.dobYear && state.dobDay ? null : 'missing'),
  };
}

function appRefresh(state: JoinState) {
  return {
    ...state,
    ...defaultState,
  };
}

function validateConfirmationField(
  originalFieldValue,
  confirmationFieldValue,
  showMissing
) {
  if (originalFieldValue === confirmationFieldValue) {
    return true;
  }

  // if nothing has been entered yet and we have not tried submitting yet, no error
  if (!confirmationFieldValue && !showMissing) {
    return true;
  }

  return false;
}
