import { PapiTopic } from '@wix/da-papi-types';

export const TOPIC_SELECT = 'TOPIC_SELECT';
export const TOPIC_UNSELECT = 'TOPIC_UNSELECT';
export const TOPIC_SUGGESTIONS = 'TOPIC_SUGGESTIONS';
export const TOPIC_LIST_UPDATE = 'TOPIC_LIST_UPDATE';

export function topicSelect(topicIdHistory: number[][], newTopicId: number) {
  return { type: TOPIC_SELECT, topicIdHistory, newTopicId };
}

export function topicUnselect(topicIdHistory: number[][], oldTopicId: number) {
  return { type: TOPIC_UNSELECT, topicIdHistory, oldTopicId };
}

export function topicSuggestions(topicIdHistory: number[][]) {
  return { type: TOPIC_SUGGESTIONS, topicIdHistory };
}

export function topicListUpdate(
  availableTopics: PapiTopic[],
  newTopicIds: number[]
) {
  return { type: TOPIC_LIST_UPDATE, availableTopics, newTopicIds };
}
