import { AppState } from '../types/store';

export function isRefererOauth(state: AppState) {
  if (state.referer) {
    try {
      const referer = new URL(state.referer);
      if (referer.searchParams.has('redirect_uri')) {
        const redirectUri = new URL(referer.searchParams.get('redirect_uri')!);
        if (redirectUri.protocol === 'oauth:') {
          return true;
        }
      }
    } catch (e) {}
  }
  return false;
}
