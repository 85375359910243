import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { URLS } from '@wix/da-url';
import { getGlobalClassnamesForContext } from '@wix/da-react-context/pkg/helpers';
import { SisuContext } from '@wix/da-shared-react/pkg/Context/SisuContext';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { ThemeOptions } from '@wix/da-react-context/pkg/ThemeContext';
import LoginPage from '../Pages/LoginPage';
import LogoutOauthPage from '../Pages/LogoutOauthPage';
import ForgotPage from '../Pages/ForgotPage';
import IntentsPage from '../Pages/IntentsPage';
import ContentFilterPage from '../Pages/ContentFilterPage';
import TopicsPage from '../Pages/TopicsPage';
import JoinOrIntentsPage from '../Pages/JoinOrIntentsPage';
import StartPage from '../Pages/StartPage';
import TFAVerificationPage from '../Pages/TFAVerificationPage';
import AppAuthorizationPage from '../Pages/AppAuthorizationPage';
import ResetPasswordPage from '../Pages/ResetPasswordPage';
import MuroModalClosePage from '../Pages/MuroModalClosePage';
import ManageCookiesPage from '../Pages/ManageCookiesPage';
import DiscordConnectedPage from '../Pages/DiscordConnectedPage';
import PasswordPage from '../Pages/PasswordPage';
import TFAReminderPage from '../Pages/TFAReminderPage';
import decorateComponentWithProps from 'decorate-component-with-props';

export interface Props {
  loggedin?: boolean;
  language: string;
  referer?: string;
  baseDaUrl?: string;
  environment?: RuntimeEnvironment;
  isTFASignIn?: boolean;
}

const SSRRedirect = ({ to }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.redirectTo = to;
      }
      return <Redirect to={to} />;
    }}
  />
);

const LoggedoutRoute = ({ loggedin, referer, ...rest }) =>
  loggedin ? <SSRRedirect to={referer} /> : <Route {...rest} />;

const LoggedinRoute = ({ loggedin, referer, ...rest }) =>
  loggedin ? <Route {...rest} /> : <SSRRedirect to="/users/join-login" />;

class App extends React.Component<Props> {
  render() {
    const { language, environment } = this.props;

    return (
      <SisuContext.Provider value={true}>
        <DaApp
          bodyClassName={getGlobalClassnamesForContext({
            isSisu: true,
          })}
          environment={environment}
          language={language}
          theme={ThemeOptions.LIGHT}
        >
          {this.renderRoutes()}
        </DaApp>
      </SisuContext.Provider>
    );
  }

  renderRoutes() {
    const { isTFASignIn } = this.props;
    return (
      <Switch>
        {/* signin flow */}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/users/forgot"
          component={ForgotPage}
        />
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/_sisu/do/step2"
          component={PasswordPage}
        />
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/users/login"
          component={LoginPage}
        />
        <LoggedinRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/users/logout/oauth2"
          component={LogoutOauthPage}
        />
        <LoggedinRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/_sisu/do/tfa_reminder"
          component={TFAReminderPage}
        />

        {/* signup flow */}
        <LoggedinRoute
          loggedin={this.props.loggedin}
          path="/join/intent"
          referer={this.props.referer}
          component={IntentsPage}
        />
        <LoggedinRoute
          loggedin={this.props.loggedin}
          path="/join/content-filter"
          referer={this.props.referer}
          component={ContentFilterPage}
        />
        <LoggedinRoute
          loggedin={this.props.loggedin}
          path="/join/topics"
          referer={this.props.referer}
          component={TopicsPage}
        />

        {/* Reset passsword page */}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/users/reset-password/:username/:passkey"
          component={ResetPasswordPage}
        />

        {/* 3rd party apps oauth flow */}
        <LoggedinRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/settings/applications"
          component={AppAuthorizationPage}
        />

        {/* When a logged out user tries to authorize a 3rd party app they arrive here.
            We then prepare a redirect with the right referer so they go to authorization after signup/signin */}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/join/oauth2"
          component={({ location }) => {
            const params = new URLSearchParams(location.search);
            const view = params.get('view');
            params.delete('view');

            const redirectPath = view === 'login' ? '/users/login' : '/join';
            const referer = `${URLS.OAUTH_AUTHORIZE}?${params.toString()}`;
            const redirectParams = new URLSearchParams({
              referer,
              oauth: '1',
            });
            return (
              <SSRRedirect
                to={`${redirectPath}?${redirectParams.toString()}`}
              />
            );
          }}
        />

        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/join"
          component={JoinOrIntentsPage}
        />

        {/* modal routes */}
        {/* T54992 */}
        <Route path="/_sisu/modals/closemodal" component={MuroModalClosePage} />

        {/* post routes */}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/_sisu/do/signin"
          component={
            isTFASignIn
              ? TFAVerificationPage
              : decorateComponentWithProps(LoginPage, { isSignin: true })
          }
        />
        {isTFASignIn && (
          <LoggedoutRoute
            loggedin={this.props.loggedin}
            referer={this.props.referer}
            path="/_sisu/do/signin_tfa"
            component={TFAVerificationPage}
          />
        )}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/_sisu/do/forgot"
          component={ForgotPage}
        />
        <Route path="/_sisu/do/signup" component={JoinOrIntentsPage} />
        <Route path="/_sisu/do/signup2" component={JoinOrIntentsPage} />
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          path="/_sisu/do/reset_password"
          component={ResetPasswordPage}
        />

        {/* Root */}
        <LoggedoutRoute
          path="/users/join-login"
          exact
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          component={StartPage}
        />

        <Route
          path="/_sisu/discord/done"
          exact
          component={DiscordConnectedPage}
        />
        <Route path="/_sisu/_cookies" exact component={ManageCookiesPage} />

        {/* Default fallback - show root if nothing else matches */}
        <LoggedoutRoute
          loggedin={this.props.loggedin}
          referer={this.props.referer}
          render={() => <SSRRedirect to="/users/join-login" />}
        />
      </Switch>
    );
  }
}

export default App;
export { LoggedoutRoute };
