import { createAction } from 'redux-actions';

import { BI_SISU_EVENT, AuthMode } from './bi';
import {
  BiData,
  IntentOptionToggleBiEvent,
  IntentShowBiEvent,
  IntentSubmitBiEvent,
} from '@wix/da-bi/pkg/events';

export const screenLoaded = createAction(BI_SISU_EVENT, (authMode: AuthMode) =>
  BiData<IntentShowBiEvent>({
    evid: 220,
    auth_mode: authMode,
  })
);

export const toggleIntent = createAction(
  BI_SISU_EVENT,
  (option: string, authMode: AuthMode, newState: boolean) =>
    BiData<IntentOptionToggleBiEvent>({
      evid: 221,
      auth_mode: authMode,
      new_state: newState ? 1 : 0,
      option,
    })
);

export const clickContinue = createAction(BI_SISU_EVENT, (authMode: AuthMode) =>
  BiData<IntentSubmitBiEvent>({
    evid: 226,
    auth_mode: authMode,
  })
);
