import { connect, MapStateToProps } from 'react-redux';
import { getIsLoggedIn } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { Props } from './App';
import { AppState } from '../../types/store';

type StateProps = Pick<
  Props,
  'referer' | 'loggedin' | 'environment' | 'baseDaUrl' | 'isTFASignIn'
>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  referer: state.referer,
  loggedin: getIsLoggedIn(state),
  environment: state.environmentType,
  baseDaUrl: state.baseDaUrl,
  isTFASignIn: Boolean(state.login.tfaUserData),
});

export default connect(mapStateToProps);
