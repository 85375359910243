import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { LoggedOutReturnType } from '@wix/da-shared-react/pkg/redux/loggedout';
import SisuPage, {
  SisuPageHeader,
  SisuPageSubHeader,
  SisuPageContent,
  SisuPageFooter,
} from '../SisuPage';
import JoinForm from '../../SignupSignin/JoinForm';
import PageErrorMessage from '../../SignupSignin/PageErrorMessage';

import s from './JoinPage.scss';

export interface Props {
  urlParams: string;
  urlParamsWithHeaderReferer?: string;
  refererType?: string;
  generalError?: string;
  socialLoginError?: string;
  daProtect?: boolean;
  screenLoaded?(): void;
  loginClicked?(): void;
  termsClicked?(): void;
  policyClicked?(): void;
}

const JoinPage: React.FC<Props> = ({
  urlParams,
  urlParamsWithHeaderReferer,
  refererType,
  generalError,
  socialLoginError,
  daProtect,
  screenLoaded,
  loginClicked,
  termsClicked,
  policyClicked,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    screenLoaded && screenLoaded();
  }, [screenLoaded]);
  const isMobile = useContext(MobileContext);
  const loginUrl = `/users/login${urlParams}`;
  const loginUrlWithHeaderReferer = `/users/login${urlParamsWithHeaderReferer}`;
  const isAnonReferer = refererType === LoggedOutReturnType.JOIN_ONLY;
  let pageHeaderKey;

  if (isAnonReferer) {
    pageHeaderKey = 'join.header.long.anonCheckout';
  } else if (isMobile) {
    pageHeaderKey = 'join.header.short';
  } else if (daProtect) {
    pageHeaderKey = 'join.header.long.protect';
  } else {
    pageHeaderKey = 'join.header.long';
  }

  return (
    <SisuPage
      bodyClassName={s['body']}
      title={daProtect ? t('join.page.title.protect') : t('join.page.title')}
      heroTextKey={isAnonReferer ? 'join.page.anonCheckout' : 'join.page'}
      origin="/join/"
      errorTextKey="join"
      errorType={generalError}
      className={s['updated-ui']}
      heroClassName={isAnonReferer ? s['anon-referer-hero'] : undefined}
    >
      <SisuPageHeader>{t(pageHeaderKey)}</SisuPageHeader>
      {socialLoginError && (
        <PageErrorMessage className={s['error']}>
          {t([
            `join.error.social.${socialLoginError}`,
            'join.error.social.general',
          ])}
        </PageErrorMessage>
      )}
      <div className={s['content-wrapper']}>
        <SisuPageContent className={s['content']}>
          <JoinForm />
        </SisuPageContent>
        <SisuPageSubHeader>
          {t('updated_ui.join.already')}{' '}
          <a
            href={
              refererType === LoggedOutReturnType.JOIN_ONLY
                ? loginUrlWithHeaderReferer
                : loginUrl
            }
            onClick={loginClicked}
          >
            {t('join.login')}
          </a>
        </SisuPageSubHeader>
      </div>
      <SisuPageFooter className={s['footer']}>
        {/* prettier-ignore */}
        <Trans  i18nKey="join.terms">
          By clicking Join, I confirm that I have read and agree to the DeviantArt <a
            href={URLS.TOS}
            onClick={termsClicked}
          >
            Terms and Conditions
          </a> and <a
            href={URLS.PRIVACY}
            onClick={policyClicked}
          >
            Privacy Policy
          </a>
        </Trans>
      </SisuPageFooter>
    </SisuPage>
  );
};
JoinPage.displayName = 'JoinPage';

export default JoinPage;
