import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import s from './SisuPageError.scss';

export interface Props {
  errorTextKey?: string;
  errorType: string;
  rootPath: string;
  origin?: string;
  refreshApp: () => void;
  hardRefresh?: boolean;
  loginRefresh?: boolean;
}

const SisuPageError: React.FC<Props> = ({
  errorTextKey,
  errorType,
  rootPath,
  origin,
  refreshApp,
  hardRefresh,
  loginRefresh,
}) => {
  const { t } = useTranslation();
  return (
    <div className={s['error-content']}>
      <img
        className={s['error-shroom']}
        src={Url.staticCDNLink('signinup/mushroom.png')}
        alt="Oh noes"
      />
      <div className={s['error-header']}>
        {t([
          `${errorTextKey}.error.general.${errorType}`,
          `error.general.${errorType}`,
          'error.general',
        ])}
      </div>
      <div className={s['error-detail']}>
        {t([
          `${errorTextKey}.error.general.${errorType}.detail`,
          `error.general.${errorType}.detail`,
          'error.general.detail',
        ])}
      </div>
      <a
        href={rootPath + origin}
        className={s['error-refresh']}
        onClick={e => {
          e.preventDefault();
          if (loginRefresh) {
            window.location.assign('/users/login'); // TODO find better const
            return;
          }
          if (hardRefresh) {
            window.location.reload();
            return;
          }
          refreshApp();
        }}
      >
        {t('communitycard.refresh')}
      </a>
    </div>
  );
};
SisuPageError.displayName = 'SisuPageError';

export default SisuPageError;
