import { connect, MapStateToProps } from 'react-redux';
import { Props } from './CompleteRedirectForm';
import { AppState } from '../../../types/store';

type StateProps = Pick<Props, 'referer'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  referer: state.referer,
});

export default connect(mapStateToProps);
